import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, catchError, forkJoin, map, of, throwError } from 'rxjs';
import { concatMap, mergeMap, retry, take, takeLast } from 'rxjs/operators';

import { ServicesNames } from '@core/enums';
import { manejarError, superUrl } from '@core/helpers';
import { GlobalVarService } from '@core/services';
import { amazon, api_key_tap_web, environment } from '@envs/environment';
import { PagosPlazos } from '@equipos/interfaces';
import { saveReservationIDAndSerie, saveSessionTagGetProperties, selectClientAttributesFeatures, selectGlobalAttributesFeatures, selectTagGetProperties } from '@store/index';
import { AplyCreditDTO, ConsultResourseResponse, CreditBureauResponse, DeductibleDTO, DeductibleModel, EvaluatorResponse, GenerateNoteResponse, GeneratePhoneQuoteResponse, PayPhoneRequest, PayPhoneResponse, ReserveResourceResponse, ResponseSessionTagsGet, TerminaFlujoResponseInterface } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class PagoService {
  readonly #http = inject(HttpClient);
  readonly #store = inject(Store);
  readonly #globalVarService = inject(GlobalVarService);

  readonly #getClientAttributes = this.#store.selectSignal(selectClientAttributesFeatures);
  readonly #getGlobalAttributes = this.#store.selectSignal(selectGlobalAttributesFeatures);
  readonly #sessionTagGetProperties = this.#store.selectSignal(selectTagGetProperties);


  get url() {
    return superUrl;
  }

  paymentServicesIcon$ = new BehaviorSubject<{ name: string, icon: string }>({ name: '', icon: '' });

  set paymentServicesIconData$(data: { name: string; icon: string; }) {
    this.paymentServicesIcon$.next(data)
  }

  sessionTagsGet() {
    const headers = new HttpHeaders({
      'x-api-key': environment.apiKey_sessionTagGet
    });

    this.#globalVarService.loadingText.set('Preparando tu Amigo Paguitos, no actualices ni cierres el  navegador hasta concluir tu trámite.');

    return this.#http.post<ResponseSessionTagsGet>(
      `${this.url}/${ServicesNames.SESSION_TAG_GET}`,
      {},
      { headers }
    ).pipe(
      map((response) => {
        if (!response.process) throw response.message;

        const { sessionId, orgId, sessionKey } = response.responseObject;
        this.#store.dispatch(saveSessionTagGetProperties({ sessionId, orgId, sessionKey }));

        (window as any)['threatmetrix']?.profile('h.online-metrix.net', orgId, sessionId);

        return { sessionId, orgId, sessionKey }
      }),
      catchError((error) => manejarError(error))
    )
  }

  enviocredenciales(obj: { payload: { cuenta: string | null; }; }) {
    const headers = new HttpHeaders({
      'x-api-key': environment.apiKey_generarContrato
    });

    const newPayload = {
      payload: {
        numCuenta: obj.payload.cuenta
      }
    }
    const body = {
      ...newPayload
    }
    return this.#http.post(
      `${this.url}/enviarCredeciales`,
      body,
      { headers }
    ).pipe(
      map((response: any) => {
        if (!response.process) throw response.message;
        return response.responseObject
      }),
      catchError((error) => manejarError(error))
    )
  }

  callEvaluatorAndSeverityLevel() {
    return this.evaluator().pipe(
      concatMap(({icc, bcc}) => this.severityLevel({icc, bcc}))
    );
  }

  terminaFlujo() {
    const headers = new HttpHeaders({
      'x-api-key': environment.apiKey_terminaFlujo
    });

    return this.#http.post<TerminaFlujoResponseInterface>(`${this.url}/terminaFlujo`,
      {},
      { headers }
    ).pipe(
      map((response) => {
        if (!response.process) throw response.message;
        return response.message;
      }),
      catchError((error) => manejarError(error))
    )
  }

  erroresPersonalizado(servicio: string, error?: string) {
    const defaultErrorMessage = `Tu pago se efectuó correctamente, el equipo de amigo paguitos se comunicará contigo para completar el proceso. Le proporcionamos su número de folio : ${sessionStorage.getItem('folioEnvio')} y el número de reserva de su equipo ${sessionStorage.getItem('folioReserva')}`;

    let mensajeError;

    switch (servicio) {
      case 'confirmacionPago':
        mensajeError = error || defaultErrorMessage;
        break
      default:
        mensajeError = defaultErrorMessage;
        break;
    }

    return mensajeError;
  }

  private updateIndex = 0;

  callServicesRecursively(services: any[], index = 0): Observable<any> {
    let serviceName = ''
    return of(...services.slice(index)).pipe(
      mergeMap((service, indexMergeMap) => {
        this.updateIndex = indexMergeMap;
        serviceName = Object.keys(service)[0];

        /**
         * Nuevo código a probar
         */
        // console.log({service, serviceName, index: this.updateIndex});
        return this.callService(service).pipe(
          map((response: any) => {
            if (serviceName === 'generarNota') {
              const payload = services[indexMergeMap + 1]['putSolicitud'].body;
              payload.payload.factura = response.responseObject.generarNotaVentaResponse.numeroFactura;
            }
            return response;
          }),
          map((response: any) => {
            if (serviceName === 'obtenercontrato') {
              const payload = services[indexMergeMap + 1]['generarContrato'].body;
              payload.payload.contrato = response.responseObject.data.contrato;
            }
            return response;
          }),
          map((response: any) => {
            if (serviceName === 'generarContrato') {
              const firmaContratoPayload = services[indexMergeMap + 1]['firmaContrato'].body;
              firmaContratoPayload.payload.cuenta = sessionStorage.getItem('externalId');
              firmaContratoPayload.payload.signatureId = response?.responseObject?.id;
              firmaContratoPayload.payload.signatureRequestId = response?.responseObject?.signature_request_id;


              const saveContractPayload = services[indexMergeMap + 2]['saveContract'].body;
              saveContractPayload.payload.cuenta = sessionStorage.getItem('externalId');
              saveContractPayload.payload.signatureId = response?.responseObject?.id;
              saveContractPayload.payload.signatureRequestId = response?.responseObject?.signature_request_id;
            }

            return response
          }),
          map((response: any) => {
            if (serviceName === 'detalleCitaEnvio') {
              const generateValuestoEnviarCorreoService = this.generateEmailRequest(response.responseObject);

              const enviaCorreoPayload = services[indexMergeMap + 1]['enviaCorreo'].body.payload;
              services[indexMergeMap + 1]['enviaCorreo'].body.payload = {
                ...enviaCorreoPayload,
                ...generateValuestoEnviarCorreoService
              }
            }

            return response;
          }),
        )
      }, 1),
      catchError(() => {
        return throwError(() => new Error(JSON.stringify({
          message: this.erroresPersonalizado(serviceName),
          service: serviceName,
          index: this.updateIndex
        })))
      }),
      takeLast(1)
    );
  }


  private callService(service: any) {
    const serviceName = Object.keys(service)[0];
    const payload = service[serviceName].body;

    const headers = new HttpHeaders({
      'x-api-key': api_key_tap_web
    });
    const body = {
      ...payload
    }

    return this.#http.post(
      `${this.url}/${serviceName}`,
      body,
      { headers })
      .pipe(
        map((response: any) => {
          if (!response.process) {
            throw new Error(JSON.stringify(
              {
                message: this.erroresPersonalizado(service, response.message),
                service,
                index: this.updateIndex
              }))
          }
          return response;
        }),
        catchError((error) => {
          if (error instanceof HttpErrorResponse) {
            return throwError(() => new Error(JSON.stringify({
              message: this.erroresPersonalizado(service),
              service,
              index: this.updateIndex
            })))
          }
          return throwError(() => error);
        }),
        retry(2),
        take(1)
      )
  }
  callAsyncRecursively(services: any[]) {
    const headers = new HttpHeaders({
      'x-api-key': api_key_tap_web
    });

    const maxRetries = 2;

    const failedServices = new Set();

    // Mapea cada servicio a un Observable que realiza la llamada HTTP
    const observables = services.map(service => {
      const getServiceName = Object.getOwnPropertyNames(service)[0];
      const payload = service[getServiceName].body;

      const body = {
        ...payload
      }

      return this.#http.post<any>(
        `${this.url}/${getServiceName}`,
        body,
        { headers }
      ).pipe(
        map((response: any) => {
          if (!response.process) {
            failedServices.add(getServiceName);
            throw new Error(JSON.stringify({
              message: this.erroresPersonalizado(service, response.message),
              service
            }));
          }

          return response
        }),
        catchError(err => {
          return throwError(() => ({ failedServices, error: err }))
        }), // Captura errores y emite un objeto con el servicio y el error
        retry(maxRetries), // Reintenta hasta el número máximo de veces
        take(1),
      )
    }
    );

    // Combina todos los observables en uno solo
    return forkJoin(observables);
  }

  /** Método de llamado dinámico para los servicios del módulo de pago */
  callServicesOfPaymentModule(payload: any, service: string) {
    const headers = new HttpHeaders({
      'x-api-key': api_key_tap_web
    });

    const body = {
      ...payload
    }
    return this.#http.post<any>(
      `${this.url}/${service}`,
      body,
      { headers }
    ).pipe(
      map((response) => {
        if (!response.process) throw response.message;
        return response.message
      }),
      catchError((error) => manejarError(error))
    )
  }

  /** Registrar el servicio que marca error del modulo de pago */
  registroLogLambda(body: object, service: string) {/////
    // private generateGlobalBody(): any { //  body global body, service consultar recurso al generar line de captura;
    const headers = new HttpHeaders({
      'x-api-key': environment.apiKey_registroLogLambda
    });

    const newBody = {
      payload: {
        jsonPayload: body,
        estatus: 'ERROR',
        servicio: service
      }
    };
    return this.#http.post<any>(
      `${this.url}/registroLogLambda`,
      newBody,
      { headers }
    ).pipe(
      retry(3),
      map((response) => {
        if (!response.process) throw 'Lamentablemente no se pudo registrar el servicio y tendrá que  ';
        return response.message;
      }),
      catchError((error) => manejarError(error))
    )
  }

  /** Obtener el servicio que marca error del modulo de pago */
  getLogLambda() {
    const headers = new HttpHeaders({
      'x-api-key': environment.apiKey_getLogLambda
    });

    const body = {
      payload: null
    };

    return this.#http.post<any>(
      `${this.url}/getLogLambda`,
      body,
      { headers }
    ).pipe(
      map((response) => {
        if (!response.process) throw response.message;
        return {
          body: response.responseObject.object as string,
          service: response.responseObject.servicio
        };
      }),
      catchError((error) => manejarError(error))
    )
  }

  /** Método que llama al servicio /${environment.ambiente}/motorPago */
  payForThePhone(obj: PayPhoneRequest) {
    const headers = new HttpHeaders({
      'x-api-key': environment.apiKey_motorPago
    });


    obj.payload.numCuenta = this.#getClientAttributes().externalID!;
    obj.payload.orgId = this.#sessionTagGetProperties()!.orgId;
    obj.payload.sessionId = this.#sessionTagGetProperties()!.sessionId;
    obj.payload.sessionKey = this.#sessionTagGetProperties()!.sessionKey;

    return this.#http.post<PayPhoneResponse>(
      `${this.url}/${ServicesNames.CONFIRMACION_PAGO}`,
      {
        ...obj
      },
      { headers }
    ).pipe(
      map((response) => {
        if (!response.process) throw response.message;
        return response;
      }),
      catchError((error) => manejarError(error)),
      take(1)
    );
  }

  /** Método que llama al servicio /${environment.ambiente}/ConsultaRecurso */
  consultResource(obj: any) {
    const headers = new HttpHeaders({
      'x-api-key': environment.apiKey_consultaRecurso
    });

    const body = {
      ...obj
    }

    return this.#http.post<ConsultResourseResponse>(
      `${this.url}/consultaRecurso`,
      body,
      { headers }
    ).pipe(
      map((response) => {
        if (!response.process) throw response.message;
        return response.responseObject
      }),
      catchError(() => manejarError())
    )
  }

  /** Método que llama al servicio /${environment.ambiente}/ReservaRecurso */
  reserveResource(obj: any) {
    const headers = new HttpHeaders({
      'x-api-key': environment.apiKey_reservaRecurso
    });

    const body = {
      ...obj
    }

    return this.#http.post<ReserveResourceResponse>(
      `${this.url}/reservaRecurso`,
      body,
      { headers }
    ).pipe(
      map((response) => {
        if (!response.process) {
          if (response.message === "ERROR: Error Fatal: com.ecacs.ecacsservicev7_ws.GeneralException: ExcepcionProveedor") {
            throw 'Este equipo no se encuentra disponible por el momento, por favor seleccione otro.';
          }
          throw response.message
        }

        const reservationId = response.responseObject.reservarRecursoResponse.idReservacion;
        const serie = response.responseObject.reservarRecursoResponse.recurso[0].serie[0];

        this.#store.dispatch(saveReservationIDAndSerie({ reservationId, serie }))
        return response;
      }),
      catchError((error) => manejarError(error))
    );
  }

  /** Método que llama al servicio /${environment.ambiente}/cotizacion */
  generateQuote() {
    const headers = new HttpHeaders({
      'x-api-key': environment.apiKey_cotizacion
    });

    const getPaymentTerms: PagosPlazos = JSON.parse(sessionStorage.getItem('pagosPlazos') || '[]');

    const body = {
      payload: {
        ...getPaymentTerms,
        periodicidad: getPaymentTerms.periodicidad.toLowerCase()
      }
    }

    return this.#http.post<GeneratePhoneQuoteResponse>(
      `${this.url}/cotizacion`,
      body,
      { headers }
    ).pipe(
      map((response) => {
        if (!response.process) throw response.message;
        return response.responseObject;
      }),
      catchError((error) => manejarError(error))
    );
  }

  /** Método que llama al servicio /${environment.ambiente}/GenerarNota */
  generateNote(obj: any) {
    const headers = new HttpHeaders({
      'x-api-key': environment.apiKey_generarNota
    });

    const body = {
      ...obj
    }

    return this.#http.post<GenerateNoteResponse>(
      `${this.url}/generarNota`,
      body,
      { headers }
    ).pipe(
      map((response) => {
        if (!response.process) throw response.message;

        const { numeroFactura, numeroOrden } = response.responseObject.generarNotaVentaResponse;
        sessionStorage.setItem('factura', numeroFactura);
        sessionStorage.setItem ('numeroOrden', numeroOrden);
        return numeroFactura;
      }),
      catchError((error) => manejarError(error))
    );
  }

  /** Método que sirve para cancelar la facturación */
  cancelarNota(region = 9) {
    const headers = new HttpHeaders({
      'x-api-key': environment.apiKey_generarNota
    });

    const body = {
      payload: {
        numeroOrder: sessionStorage.getItem('numeroOrden'),
        almacen: sessionStorage.getItem('claveOficinaAlmacen'),
        region,
        sku: sessionStorage.getItem('codigo'),
        imei: sessionStorage.getItem('serie'),
        agente: `${ sessionStorage.getItem('idEmpleado') }|${ sessionStorage.getItem('agente') }`
      }
    }

    return this.#http.post<any>(
      `${this.url}/cancelarNota`,
      body,
      { headers }
    ).pipe(
      map((response) => {
        if (!response.process) throw response.message;
        return response;
      }),
      catchError((error) => manejarError(error))
    );
  }

  /** Método para llamar al servicio /${environment.ambiente}/GenerarContrato */
  generateContract(obj: any) {
    const headers = new HttpHeaders({
      'x-api-key': environment.apiKey_generarContrato
    });

    const body = {
      ...obj
    }

    return this.#http.post(
      `${this.url}/generarContrato`,
      body,
      { headers }
    ).pipe(
      map((response: any) => {
        if (!response.process) throw response.message;
        return response.responseObject
      }),
      catchError((error) => manejarError(error))
    )
  }

  /** Método para llamar al servicio /${environment.ambiente}/cancelarReserva */
  cancelResource(obj: any) {
    const headers = new HttpHeaders({
      'x-api-key': environment.apiKey_cancelarReseva
    });

    const body = {
      ...obj
    }
    return this.#http.post(
      `${this.url}/cancelarReserva`,
      body,
      { headers }
    ).pipe(
      catchError(() => manejarError())
    );
  }

  evaluator() {
    const headers = new HttpHeaders({
      'x-api-key': environment.apiKey_evaluador
    });

    const body = {
      payload: {
        region: this.#getClientAttributes()?.cac?.region,
        idTransaccion: this.#getClientAttributes()?.idTransaccion,
      }
    }
    return this.#http.post<EvaluatorResponse>(
      `${this.url}/${ServicesNames.EVALUADOR}`,
      body,
      { headers }
    ).pipe(
      map((response) => {
        if (!response.process) throw response.message;

        const { buro } = response.responseObject.payload;
        return { icc: buro.icc, bcc: buro.score }
      }),
      catchError(() => manejarError())
    )
  }


  /** Método que llama al servicio que se encarga de revisar el buró de crédito */
  public severityLevel({icc, bcc}: {icc: string; bcc: string;}): Observable<string> {
    const headers = new HttpHeaders({
      'x-api-key': environment.apiKey_severityLevel
    });

    const body = this.generateCreditBureauRequest({icc, bcc});

    return this.#http.post<CreditBureauResponse>(
      `${this.url}/${ServicesNames.SEVERITY_LEVEL}`,
      body,
      { headers }
    ).pipe(
      map((response) => {
        if (!response.process) throw response.message;
        return response.responseObject.scoreResponse.score_hf;
      }),
      //oferta por omision
      catchError(() =>  '0')
    );
  }


  private generateCreditBureauRequest({icc, bcc}: {icc: string, bcc: string}) {
    return {
      payload: {
        code: 0,
        emailedge: '1',
        icc: icc,
        bcc: bcc,
        canal: this.#getGlobalAttributes()?.canal || 'CAC',
        fzavta: this.#getGlobalAttributes()!.fuerzaVenta,
        cp: this.#getClientAttributes()!.codigoPostal,
        cuenta: this.#getClientAttributes()!.externalID,
        region: this.#getGlobalAttributes()!.region,
        idTransaccion: this.#getClientAttributes()!.idTransaccion
      }
    }
  }


  generateEmailRequest(datos: any) {
    const nombre = `${datos.nombre} ${datos.apPaterno} ${datos.apMaterno}`

    return {
      nombre: nombre,
      folioEntrega: datos.folioCita,
      fechaEntrega: datos.fechaEntrega,
      horarioEntrega: datos.horaEntrega,
      paqueteria: datos.mensajeria,
    }
  }

  getDeductible(data: DeductibleModel): Observable<DeductibleDTO> {
    const headers = new HttpHeaders({
      'x-api-key': environment.apiKey_asurion
    });
    return this.#http.post<DeductibleDTO>(
      `${amazon.apiUrlAmazon}/${environment.ambiente}/asurionDeductible`,
      data,
      {headers})
      .pipe(
        map((response) => {
          if (!response.process) throw response.message;
          return response;
        }),
        catchError(() => manejarError())
      )
  }
  aplyCreditAsurion(data: AplyCreditDTO): Observable<any> {
    const headers = new HttpHeaders({
      'x-api-key': environment.apiKey_asurion
    });
    return this.#http.post<any>(
      `${amazon.apiUrlAmazon}/${environment.ambiente}/asurionDeductible`,
      data,
      {headers})
      .pipe(
        map((response) => {
          if (!response.process) throw response.message;
          return response;
        }),
        catchError(() => manejarError())
      )
  }

}
