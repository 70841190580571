import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { calculate } from '@equipos/detalle-producto/helpers';
import { savePayment, savePhone, savePutSolicitud, selectCreditLimit, selectPagosPlazos, selectPeriodicidad, selectPhone, selectPutSolicitud } from '@store/index';
import moment from 'moment';
import { PagosPlazos } from '@equipos/interfaces';

@Injectable({
  providedIn: 'root'
})
export class PaymentRecalculationsService {
  readonly #store = inject(Store);

  readonly #phone = this.#store.selectSignal(selectPhone);
  readonly #limitCredit = this.#store.selectSignal(selectCreditLimit);
  readonly #pagosPlazos = this.#store.selectSignal(selectPagosPlazos);
  readonly #putSolicitud = this.#store.selectSignal(selectPutSolicitud);
  readonly #periodicidad = this.#store.selectSignal(selectPeriodicidad);

  readonly #creditLimit = this.#store.selectSignal(selectCreditLimit);

  get phone() {
    return this.#phone()!;
  }

  recalcularEngancheYCuotas(message: string) {
    let pagosPlazos = this.#pagosPlazos();
    let phone = this.phone;
    let putSolicitud = this.#putSolicitud()!;

    pagosPlazos.cuotas = [];
    /** ReGEX para recuperar el precio SAP y recuperar el valor **/
    const regex = /PRECIO SAP\((\d+(?:\.\d+)?)\)/;
    const matches = message.match(regex);

    if (matches && matches.length > 1) {
      let precioSap = matches[1];
      if (precioSap === '0') return false;

      precioSap = (Number(precioSap) * 1.16).toFixed(2)

      const pagoInicial = this.calcularPagoInicial(Number(precioSap));


      phone.precio_iva = precioSap;
      phone.pagoInicial = pagoInicial;
      this.#store.dispatch(savePhone({ value: phone }));

      const { pagoPorCuotas } = calculate(Number(precioSap), pagoInicial, pagosPlazos.factor, pagosPlazos.nro_cuotas);


      pagosPlazos.enganche = Number(pagoInicial.toFixed(2));
      pagosPlazos.cuotas = this.getNewCoutas(pagosPlazos.nro_cuotas, pagoPorCuotas);
      pagosPlazos.producto.precio = precioSap;
      this.#store.dispatch(savePayment({ value: pagosPlazos }));

      putSolicitud.precio = precioSap;
      putSolicitud.enganche = pagoInicial;
      putSolicitud.updateTelefono = true;
      this.#store.dispatch(savePutSolicitud({ value: putSolicitud }));
      return true;
    }

    return false;
  }

  recalcularPagoInicial = (precioConIVA: number, enganche: number) => {
    const limiteCredito = this.#creditLimit() || 0;
    const precioIva = Number(precioConIVA);
    const engancheMinimo = Number((precioIva * enganche).toFixed(2));
    const pagoInicial = Number((precioIva - limiteCredito).toFixed(2));

    if (enganche === 0) return 0;
    if (pagoInicial > engancheMinimo) return pagoInicial;
    return engancheMinimo;
  }


  private calcularPagoInicial(precioConIVA: number) {
    const enganche = this.#putSolicitud()?.enganche || 0;

    const precioIva = Number(precioConIVA);

    const engancheMinimo = Number((precioIva * enganche).toFixed(2));
    const pagoInicial = Number((precioIva - this.#limitCredit()!).toFixed(2));

    if (enganche === 0) return 0;
    if (pagoInicial > engancheMinimo) return pagoInicial;
    return engancheMinimo;
  }

  private getNewCoutas(cantidadPago: number, pagoInicial: number) {
    type cuotasType = Required<PagosPlazos['cuotas']>;

    const cuotas: cuotasType = [];

    if (this.#periodicidad().toLowerCase() === 'Semanales') {
      for (let i = 0; i < cantidadPago; i++) {
        const lastMonthSaved = cuotas[i - 1]?.vencimiento;

        let date = moment(lastMonthSaved || new Date()).add(1, 'week').format('YYYY-MM-DD');

        if (i === 0) {
          date = moment(lastMonthSaved || new Date()).add(2, 'week').format('YYYY-MM-DD');
        }
        cuotas.push({
          monto: pagoInicial.toFixed(2),
          vencimiento: date
        });
      }
    } else if (this.#periodicidad().toLocaleLowerCase() === 'Quincenales') {
      for (let i = 0; i < cantidadPago; i++) {
        const lastMonthSaved = cuotas[i - 1]?.vencimiento;
        const date = moment(lastMonthSaved || new Date()).add(2, 'week').format('YYYY-MM-DD');
        cuotas.push({
          monto: pagoInicial.toFixed(2),
          vencimiento: date
        });
      }
    } else if (this.#periodicidad().toLowerCase() === 'Mensuales') {
      for (let i = 1; i <= cantidadPago; i++) {
        const date = moment(new Date()).add(i, 'month').format('YYYY-MM-DD')
        cuotas.push({
          monto: pagoInicial.toFixed(2),
          vencimiento: date
        });
      }
    }

    return cuotas;
  }
}
