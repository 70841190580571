import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { finalize, tap } from 'rxjs';

import { GlobalVarService } from '@core/services';


let activeRequests = 0;
let isMotorCalculoRequest = false;


export const spinnerInterceptor: HttpInterceptorFn = (req, next) => {
  const router = inject(Router);
  const globalVarService = inject(GlobalVarService);

  const clonedRequest = req.clone();

  if (!isMotorCalculoRequest || !clonedRequest.url.includes('motorCalculo')) {
    activeRequests++;
    globalVarService.onShowSpinner();
  }

  // activeRequests = activeRequests + 1;
  // globalVarService.onShowSpinner();

  isMotorCalculoRequest = clonedRequest.url.includes('motorCalculo');
  return next(clonedRequest).pipe(
    tap((response: any) => {
      if (response?.body?.process === false) {
        const message = (response?.body?.message as string)?.toLocaleLowerCase();
        if (message?.includes('jwt')) {
          globalVarService.warningToastText.set(message);
          router.navigateByUrl(sessionStorage.getItem('queryParams') || '/')
        }
      }
    }),
    finalize(() => {
      activeRequests && activeRequests--;

      if (activeRequests <= 0) {
        globalVarService.onCloseSpinner();
      }
    })
  );
}
