import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';

import { ServicesNames } from '@core/enums';
import { superUrl } from '@core/helpers';
import { environment } from '@envs/environment';
import { selectIdTransaction } from '@store/index';
import { ConsultaStatusModel, InfoUserModel, LineaConecktaDTO, LineaConecktaModel } from '../models';
@Injectable({
  providedIn: 'root'
})
export class GenerarLineaConektaService {
  readonly #http = inject(HttpClient);
  readonly #store = inject(Store);

  readonly #idTransaccion = this.#store.selectSignal(selectIdTransaction);

  get url() {
    return superUrl;
  }


  lineaCaptura(data: InfoUserModel) {
    const headers = new HttpHeaders({
      'x-api-key': environment.apiKey_conekta
    });


    const body: LineaConecktaModel = {
      idTransaccionAp: this.#idTransaccion(),
      unit_price: data.monto,
      payload: {
        line_items: [
          {
            name: data.nombre_cliente,
            unit_price: 0, // Eliminarlo
            quantity: 1
          }
        ],
        currency: 'MXN',
        customer_info: {
          name: data.nombre_cliente,
          email: data.correo_electronico,
          phone: data.numero_telefonico
        },
        metadata: {
          datos_extra: '0'
        },
        charges: [
          {
            payment_method: {
              type: 'cash',
              expires_at: 0
            }
          }
        ]
      }
    }

    return this.#http.post<LineaConecktaDTO>(
      `${this.url}/${ServicesNames.GENERAR_ORDEN_CONEKTA_SERVICE}`,
      body,
      { headers }
    ).pipe(
      map((response) => {
        if (!response.process) throw 'Error';
        return response;
      })
    );
  }

  consultaStatus() {
    const headers = new HttpHeaders({
      'x-api-key': environment.apiKey_conekta
    });

    const body: ConsultaStatusModel = {
      payload: {
        idTransaccionAp: this.#idTransaccion(),
      }
    }

    return this.#http.post<any>(
      `${this.url}/${ServicesNames.GENERAR_ORDEN_CONEKTA_SERVICE}`,
      body,
      { headers }
    ).pipe(
      map((response) => {
        if (!response.process) throw 'Error';
        return response;
      })
    );
  }
}
