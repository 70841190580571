import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable, signal } from '@angular/core';
import { Store } from '@ngrx/store';
import { catchError, map, Observable } from 'rxjs';

import { ServicesNames } from '@core/enums';
import { manejarError, superUrl } from '@core/helpers';
import { OtpResponse, tipoMediosOTP, tipoStatusCliente } from '@core/interfaces';
import { environment } from '@envs/environment';
import { selectAgenteDAT, selectCanal, selectEmail, selectIdEmpleado, selectIdTransaction, selectClientPhone, selectToken } from '@store/index';
import { EnviaCorreoResponse, RegisterEmailResponse, SendOtpVerificacionResponse } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class EmailService {
  readonly #http = inject(HttpClient);
  readonly #store = inject(Store);

  readonly #getToken = this.#store.selectSignal(selectToken);
  readonly #getCanal = this.#store.selectSignal(selectCanal);
  readonly #getIdEmpleado = this.#store.selectSignal(selectIdEmpleado);
  readonly #getAgenteDAT = this.#store.selectSignal(selectAgenteDAT);
  readonly #getClientEmail = this.#store.selectSignal(selectEmail);
  readonly #getClientPhone = this.#store.selectSignal(selectClientPhone);
  readonly #getTransactionId = this.#store.selectSignal(selectIdTransaction);

  #canNavigateRoute = signal(false);
  #existErrorToNavigateRoute = signal({
    show: false,
    message: ''
  });


  get url() {
    return superUrl;
  }

  get navigateRoute() {
    return this.#canNavigateRoute.asReadonly();
  }

  get errorToNavigateRoute() {
    return this.#existErrorToNavigateRoute.asReadonly();
  }


  onNavigateRoute(value = true) {
    this.#canNavigateRoute.set(value);
  }

  onExistErrorToNavigateRoute({ show, message }: { show: boolean; message: string; }) {
    this.#existErrorToNavigateRoute.set({ show, message });
  }

  registerEmail(email: string): Observable<RegisterEmailResponse> {
    const headers = new HttpHeaders({
      'x-api-key': environment.apiKey_registrarEmail
    });

    const body = this.generateRegisterEmailRequest(email);

    return this.#http.post<RegisterEmailResponse>(
      `${ this.url }/${ServicesNames.REGISTRAR_EMAIL}`,
      body,
      { headers }
    ).pipe(
      map((response) => {
        if ( !response.process ) throw response.message;
        return response
      }),
      catchError((error) => manejarError(error))
    );
  }

  emailOtpValidation(code: string): Observable<OtpResponse>  {
    const headers = new HttpHeaders({
      'x-api-key': environment.apiKey_validarOtpEmail,
      'jwt': this.#getToken()!
    });

    const body = this.generateEmailOtpValidationRequest(code);

    return this.#http.post<OtpResponse>(
      `${ this.url }/${ServicesNames.VALIDAR_OTP_EMAIL}`,
      body,
      { headers }
    ).pipe(
      map((response) => {
        if ( !response.process ) throw response.message;
        return response
      }),
      catchError((error) => manejarError(error))
    )
  }

  resendOTPEmail(): Observable<SendOtpVerificacionResponse> {
    const headers = new HttpHeaders({
      'x-api-key': environment.apiKey_sendOTPVerificacion
    });

    return this.#http.post<SendOtpVerificacionResponse>(
      `${ this.url }/${ServicesNames.SEND_OTP_VERIFICACION}`,
      this.generateResendOTPBodyRequest(),
      { headers }
    ).pipe(
      map((response) => {
        if ( !response.process ) throw response.message;
        return response;
      }),
      catchError((error) => manejarError(error))
    );
  }


  private generateRegisterEmailRequest(email: string) {
    return {
      payload: {
        correo: email,
        medioOTP: tipoMediosOTP.EMAIL,
        statusCliente: tipoStatusCliente.NO_REGISTRADO,
        idTransaccion: this.#getTransactionId()
      }
    }
  }

  private generateEmailOtpValidationRequest(code: string) {
    let agenteId = '';
    const canal = this.#getCanal();

    if ( canal === 'DAT' ) {
      agenteId = this.#getAgenteDAT()!;
    } else {
      agenteId = this.#getIdEmpleado()!;
    }

    return {
      numeroCelular: this.#getClientPhone(),
      medioOTP: tipoMediosOTP.EMAIL,
      correo: this.#getClientEmail()!,
      idTransaccion: this.#getTransactionId(),
      OTP: code,
      agenteId
    }
  }

  private generateResendOTPBodyRequest() {
    return {
      payload: {
        correo: this.#getClientEmail(),
        medioOTP: tipoMediosOTP.EMAIL,
        statusCliente: tipoStatusCliente.REGISTRADO
      }
    }
  }
}
