import { inject, Injectable, signal } from '@angular/core';

export enum IdleUserTimes {
  IdleTime = 3000,
  CountdownTime = 600000 // 10 minutos
}


export class InactivityService {
  #show = signal(false);

  get showModal() {
    return this.#show.asReadonly();
  }

  private timeoutId!: NodeJS.Timeout;
  private countdownId!: NodeJS.Timeout;
  private countdownValue: number = 0;

  constructor() {
    this.reset();
    this.initListener();
  }

  initListener() {
    window.addEventListener('mousemove', () => this.reset());
    window.addEventListener('click', () => this.reset());
    window.addEventListener('keypress', () => this.reset());
    window.addEventListener('DOMMouseScroll', () => this.reset());
    window.addEventListener('mousewheel', () => this.reset());
    window.addEventListener('touchmove', () => this.reset());
    window.addEventListener('MSPointerMove', () => this.reset());
  }

  reset() {
    clearTimeout(this.timeoutId);
    clearTimeout(this.countdownId);
    this.startIdleTimer();
  }

  startIdleTimer() {
    this.timeoutId = setTimeout(() => {
      // console.log('Inactivity detected');
      this.startCountdown();
    }, IdleUserTimes.IdleTime);
  }

  startCountdown() {
    this.countdownValue = (IdleUserTimes.CountdownTime / 1000);
    this.countdownId = setInterval(() => {
      this.countdownValue--;
      // console.log('You will logout in:', this.countdownValue, 'seconds');
      if (this.countdownValue <= 0) {
        clearInterval(this.countdownId);
        // console.log('User idle');
        this.#show.set(true);
      }
    }, 1000);
  }

  onShow(value: boolean) {
    this.#show.set(value);
  }
}
