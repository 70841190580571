import { Injectable, signal } from '@angular/core';

import { AddressAttributesInterface, AppState, ClientAttributesInterface, GlobalAttributesInterface, PhoneAttributesInterface } from '@store/interfaces';
import { INIT_ADDRESS_STATE, INIT_CLIENT_STATE, INIT_GLOBAL_STATE, INIT_PAYMENT_STATE, INIT_PHONE_STATE } from '@store/utilities';

@Injectable({
  providedIn: 'root'
})
export class AppStoreService {
  #appStorage = signal<AppState>({
    global: INIT_GLOBAL_STATE,
    client: INIT_CLIENT_STATE,
    phone: INIT_PHONE_STATE,
    payment: INIT_PAYMENT_STATE,
    address: INIT_ADDRESS_STATE
  }
  );

  #emailOtp = signal('');


  get clientData() {
    return this.#appStorage().client;
  }

  get globalData() {
    return this.#appStorage().global;
  }

  get emailOTP() {
    return this.#emailOtp.asReadonly();
  }

  constructor() {
    this.initData();
  }


  updateGlobalData(properties: Partial<GlobalAttributesInterface>) {
    this.#appStorage.update((value) => ({ ...value, global: { ...value.global, ...properties } }));
  }

  updateClientData(properties: Partial<ClientAttributesInterface>) {
    this.#appStorage.update((values) => ({ ...values, client: { ...values.client, ...properties } }));
  }

  updatePhoneData(properties: Partial<PhoneAttributesInterface>) {
    this.#appStorage.update((value) => ({ ...value, phone: { ...value.phone, ...properties } }));
  }

  updateAddressData(properties: Partial<AddressAttributesInterface>) {
    this.#appStorage.update((value) => ({ ...value, address: {...value.address, ...properties} }));
  }

  setEmailOTP(value: string) {
    this.#emailOtp.set(value);
  }


  private initData() {
    const getClientDataFromSession = sessionStorage.getItem('client');
    const getGlobalDataFromSession = sessionStorage.getItem('global');
    const getPhoneFromSession = sessionStorage.getItem('phone');
    const getPaymentFromSession = sessionStorage.getItem('payment');
    const getAddressFromSession = sessionStorage.getItem('address');

    if (getClientDataFromSession) {
      this.#appStorage.update((value) => ({ ...value, client: JSON.parse(getClientDataFromSession) }));
    }

    if (getGlobalDataFromSession) {
      this.#appStorage.update((value) => ({ ...value, global: JSON.parse(getGlobalDataFromSession) }));
    }

    if (getPhoneFromSession) {
      this.#appStorage.update((value) => ({ ...value, phone: JSON.parse(getPhoneFromSession) }));
    }

    if (getPaymentFromSession) {
      this.#appStorage.update((value) => ({ ...value, payment: JSON.parse(getPaymentFromSession) }));
    }

    if (getAddressFromSession) {
      this.#appStorage.update((value) => ({ ...value, address: JSON.parse(getAddressFromSession) }));
    }
  }
}
