import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable, catchError, map } from 'rxjs';

import { environment } from '@envs/environment';
import { manejarError, superUrl } from '../helpers';
import { OtpResponse, PayloadOTPVerificacion } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class OtpService {
  #http = inject(HttpClient);


  get url() {
    return superUrl;
  }


  sendOTPVerificacion({ correo, ...rest }: PayloadOTPVerificacion): Observable<OtpResponse> {
    const headers = new HttpHeaders({
      'x-api-key': environment.apiKey_sendOTPVerificacion
    });

    const body = this.crearRequestOTPVerificacion({ correo, ...rest });

    return this.#http.post<OtpResponse>(
      `${ this.url }/sendOTPVerificacion`,
      body,
      { headers }
    ).pipe(
      map((response) => {
        if ( !response.process ) throw response.message;
        return response;
      }),
      catchError((error) => manejarError(error))
    )
  }

  returnProcess({ correo, ...rest }: any): Observable<any> {
    const body = this.crearRequestOTPVerificacion({ correo, ...rest });

    const headers = new HttpHeaders({
        'x-api-key': environment.apiKey_retomarTramiteSms,
        'jwt': sessionStorage.getItem('tokenTelcel')!
    });

    return this.#http.post<OtpResponse>(
      `${ this.url }/retomarTramiteSms`,
      body,
      { headers }
    ).pipe(
      map((response) => {
        if ( !response.process ) throw response.message;
        return response;
      }),
      catchError((error) => {
        return manejarError(error);
      })
    );
  }


  private crearRequestOTPVerificacion({ correo, ...rest }: PayloadOTPVerificacion) {
    return {
      payload: {
        ...rest,
        correo
      }
    }
  }
}
