import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable, signal } from '@angular/core';
import { Store } from '@ngrx/store';
import { catchError, map } from 'rxjs/operators';

import { manejarError, superUrl } from '@core/helpers';
import { amazon, environment } from '@envs/environment';
import { selectClaveOficinaAlmacen, selectFuerzaVenta } from '@store/index';
import { BranchOffice, BranchOfficesResponse } from '../interfaces';
import { ServicesNames } from '@core/enums';

@Injectable({
  providedIn: 'root'
})
export class SucursalesService {
  readonly #http = inject(HttpClient);
  readonly #store = inject(Store);

  readonly #fuerzaVenta = this.#store.selectSignal(selectFuerzaVenta);
  readonly #claveOficinaAlmacen = this.#store.selectSignal(selectClaveOficinaAlmacen);

  #branchOffices = signal<{ allBranchOffices: BranchOffice[], filteredBranchOffice: BranchOffice[] }>({
    allBranchOffices: [],
    filteredBranchOffice: []
  });

  get url() {
    return superUrl;
  }

  get branchOffices() {
    return this.#branchOffices.asReadonly();
  }

  get allBranchOffices() {
    return this.#branchOffices().allBranchOffices;
  }

  get filteredBranchOffices() {
    return this.#branchOffices().filteredBranchOffice;
  }


  getBranchOffices(region = '9') {
    const headers = new HttpHeaders({
      'x-api-key': environment.apiKey_cacsSmt
    });

    const body = {
      payload: {
        region,
        fuerzaVentas: this.#fuerzaVenta(),
        claveFzaVenta: this.#claveOficinaAlmacen()
      }
    }
    return this.#http.post<BranchOfficesResponse>(
      `${this.url}/${ServicesNames.CAC_SMS}`,
      body,
      { headers }
    ).pipe(
      map((response) => {
        if (!response.process) throw response.message;
        return response;
      }),
      map(({ responseList }) => responseList),
      catchError((error) => manejarError(error))
    );
  }

  onSetBranchOffices(branchOffices: BranchOffice[]) {
    this.#branchOffices.set({allBranchOffices: branchOffices, filteredBranchOffice: branchOffices});
  }

  onFilteredBranchOffices(branchOffices: BranchOffice[]) {
    this.#branchOffices.update(({ allBranchOffices }) => ({ allBranchOffices: allBranchOffices, filteredBranchOffice: branchOffices }));
  }
}
