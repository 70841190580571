import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService, SharedModule } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';

import { DireccionEntregaService } from '@entrega-equipo/services';
import { Phone } from '@equipos/interfaces';
import { PagoService } from '@pago/services';

@Component({
    selector: 'app-phone-not-available-dialog',
    templateUrl: './phone-not-available-dialog.component.html',
    styleUrls: ['./phone-not-available-dialog.component.scss'],
    standalone: true,
    imports: [ConfirmDialogModule, SharedModule]
})
export class PhoneNotAvailableDialogComponent implements OnInit {
  @Input() showDialog = false;
  private phone: Phone = JSON.parse(sessionStorage.getItem('phone')!);
  private idEmpleado = sessionStorage.getItem('idEmpleado');
  private agente = sessionStorage.getItem('agente');
  private clientData = JSON.parse(sessionStorage.getItem('datosCliente') || '[]');

  constructor( private direccionEntregaService: DireccionEntregaService, private pagoService: PagoService, private confirmationService: ConfirmationService, private router: Router ) { }

  ngOnInit(): void {
    this.createConfirmDialog()
  }

  private createConfirmDialog() {
    this.confirmationService.confirm({
      message: 'Lamentablemente tu equipo no está disponible',
      header: 'Lo sentimos',
      icon: 'pi pi-exclamation-triangle',
      closeOnEscape: false,
      rejectVisible: false,
      acceptLabel: 'Continuar',
      accept: () => this.onCancel()
    });
  }

  private onCancel() {
    sessionStorage.removeItem('showModal');
    sessionStorage.removeItem('tipoEntrega');
    sessionStorage.removeItem('codigo');

    this.direccionEntregaService.cancelaPaqueteria().subscribe();
    this.pagoService.cancelResource(this.cancelBookingBody()).subscribe();
    this.router.navigate( ['/equipos'], { queryParams: { path: 'equipos'} } );
  }

  private cancelBookingBody() {
    return {
      payload: {
        agente:`${this.idEmpleado}|${this.agente}`, // Estatico
        codigo:"0", // Estatico
        idReservacion:  sessionStorage.getItem('idReservacion'), // Es la respuesta del servicio de reservaREcurso reservarRecursoResponse.idReservacion
        region: sessionStorage.getItem('region'),
        serie: sessionStorage.getItem('serie'), // Es la respuesta del servicio de reservaRecurso reservarRecursoResponse.recurso[0].serie
        sku: this.phone.codigo,
        cantidad: "1",
        almacen: this.clientData?.cac?.oficina_id
     }
    }
  }


}
