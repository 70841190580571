import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { selectAgente, selectCanal, selectClaveOficinaAlmacen, selectClientPhone, selectCuotas, selectDatosEnvio, selectEmail, selectExternalID, selectFolioEnvio, selectFuerzaVenta, selectIdEmpleado, selectIdFlujo, selectIdReservacion, selectIdTransaction, selectOficinaID, selectPagosPlazos, selectPhone, selectPromotionCode, selectPutSolicitud, selectRegion, selectSeriePhone, selectSucursal, selectTagGetProperties, selectTipoEntregaSeleccionada, selectTipoPago } from '@store/index';
import { CreditCardInformation } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class GlobalBodyService {
  readonly #store = inject(Store);

  readonly #idEmpleado = this.#store.selectSignal(selectIdEmpleado);
  readonly #agente = this.#store.selectSignal(selectAgente);
  readonly #externalId = this.#store.selectSignal(selectExternalID);
  readonly #oficinaID = this.#store.selectSignal(selectOficinaID);
  readonly #region = this.#store.selectSignal(selectRegion);
  readonly #idTransaccion = this.#store.selectSignal(selectIdTransaction);
  readonly #fuerzaVenta = this.#store.selectSignal(selectFuerzaVenta);
  readonly #claveOficinaAlmacen = this.#store.selectSignal(selectClaveOficinaAlmacen);
  readonly #idFlujo = this.#store.selectSignal(selectIdFlujo);
  readonly #canal = this.#store.selectSignal(selectCanal);

  readonly #clientPhone = this.#store.selectSignal(selectClientPhone);
  readonly #emailCliente = this.#store.selectSignal(selectEmail);

  readonly #pagosPlazos = this.#store.selectSignal(selectPagosPlazos);
  readonly #putSolicitud = this.#store.selectSignal(selectPutSolicitud);
  readonly #tipoPago = this.#store.selectSignal(selectTipoPago);
  readonly #tagGetProperties = this.#store.selectSignal(selectTagGetProperties);
  readonly #cuotas = this.#store.selectSignal(selectCuotas);
  readonly #promotionCode = this.#store.selectSignal(selectPromotionCode);

  readonly #folioEnvio = this.#store.selectSignal(selectFolioEnvio);
  readonly #tipoEntregaSeleccionada = this.#store.selectSignal(selectTipoEntregaSeleccionada);
  readonly #branchOffice = this.#store.selectSignal(selectSucursal);
  readonly #datosEnvio = this.#store.selectSignal(selectDatosEnvio);

  readonly #phone = this.#store.selectSignal(selectPhone);
  readonly #idReservacion = this.#store.selectSignal(selectIdReservacion);
  readonly #serie = this.#store.selectSignal(selectSeriePhone);

  get phone() {
    return this.#phone()!;
  }

  get selectedBranchOffice() {
    return this.#branchOffice()!;
  }

  get datosEnvio() {
    return this.#datosEnvio()!;
  }


  generateConfirmacionPagoBody(cardInformation: CreditCardInformation, myIp: string) {
    console.log({ cardInformation, myIp });
    return {
      confirmacionPago: {
        body: this.generatePayPhoneBody(cardInformation, myIp)
      }
    }
  }

  generateCotizacionBody() {
    // Esta constante recupera el valor de "pagosPlazos", pero viene en plural, por ejemplo:
    // "Mensuales", "Quincenales", "Semanales"
    const periodicidad = this.#pagosPlazos().periodicidad;

    // Pasamos de plurar a singular la periodicidad y la pasamos a minusculas.
    const pluralToSingularPeriodicidad = periodicidad.substring(0, periodicidad.length - 2).toLowerCase();

    return {
      cotizacion: {
        body: {
          payload: {
            ...this.#pagosPlazos(),
            periodicidad: pluralToSingularPeriodicidad
          }
        }
      }
    }
  }

  generateGenerarNotaBody() {
    return {
      generarNota: {
        body: this.generateNoteRequest()
      }
    }
  }

  generatePutSolicitud() {
    return {
      putSolicitud: {
        body: {
          payload: {
            pasoPago: true,
            imei: this.#serie(),
            numCuenta: this.#externalId(),
            sessionId: this.#tagGetProperties()?.sessionId,
            sessionKey: this.#tagGetProperties()?.sessionKey,
            orgId: this.#tagGetProperties()?.orgId,
            updateTelefono: false,
            tipoPago: this.mapTipoPago(),
          }
        }
      }
    }
  }

  generatePostPrestamoBody() {
    return {
      postPrestamo: {
        body: {
          payload: {
            numCuenta: this.#externalId()
          }
        }
      },
    }
  }

  generateObtenerContratoBody() {
    return {
      obtenercontrato: {
        body: this.obtenerContratoRequest()
      }
    }
  }

  generateGenerarContratoBody() {
    return {
      generarContrato: {
        body: this.generateContractRequest()
      }
    }
  }

  generateFirmaContratoBody() {
    return {
      firmaContrato: {
        body: {
          payload: {
            cuenta: this.#externalId(),
            signatureId: '',
            signatureRequestId: ''
          }
        }
      }
    }
  }

  generateSaveContract() {
    return {
      saveContract: {
        body: {
          payload: {
            cuenta: this.#externalId(),
            signatureId: '',
            signatureRequestId: ''
          }
        }
      }
    }
  }

  generateDetalleCitaEnvioBody() {
    return {
      detalleCitaEnvio: {
        body: {
          payload: {
            region: this.#region(),
            fuerzaVentas: this.#fuerzaVenta(),//"CARSO",
            claveFzaVenta: this.#claveOficinaAlmacen(),//"M697-C090",
            folioCita: this.#folioEnvio(),
          }
        }
      }
    }
  }

  generateEnviaCorreoBody(isFlowSMT: boolean) {
    return {
      enviaCorreo: {
        body: {
          payload: {
            ...this.getDomicilio(),
            nombre: '',
            folioEntrega: '',
            fechaEntrega: '',
            horarioEntrega: '',
            paqueteria: '',
            nombreEquipo: this.phone.nombre_comercial || '-',
            marca: this.phone.marca || '-',
            modelo: this.phone.modelo || '-',
            telefono: "telefono",
            correoElectronicoEnvio: this.#emailCliente(),
            correoElectronicoContacto: this.#emailCliente(),
            serie: this.#serie(),
            numCuenta: this.#externalId(), // Tratar de recuperarlos
            isFlowSMT

          }
        }
      }
    }
  }

  generateEnviarCredecialesBody() {
    return {
      enviarCredeciales: {
        body: this.enviarCredencialesRequest()
      }
    }
  }

  generateReservaIMEIBody() {
    return {
      reservaImei: {
        body: {
          payload: {
            numCuenta: this.#externalId(),
            imei: this.#serie(),
          }
        }
      }
    }
  }

  generateRegistrarDispositivoBody() {
    return {
      registrarDispositivo: {
        body: {
          payload: {
            numCuenta: this.#externalId(),
            imei: null
          }
        }
      }
    }
  }

  generateActualizarStatusClienteBody(estatus = 'Incompleto') {
    return {
      actualizaEstatusCliente: {
        body: {
          payload: {
            estatus,
            flujoFinal: this.#idFlujo()
          }
        }
      }
    }
  }

  notificarDescuento() {
    return {
      apiDescuentos: {
        body: {
          payload: {
            promocion: "TI",
            operacion: "TI2",
            voucher: this.#promotionCode()?.value,
            region: this.#region(),
            canal: this.#canal(),
            cuenta: this.#externalId(),
            idTransaccion: this.#idTransaccion()
          }
        }
      }
    }
  }


  private generatePayPhoneBody({ nombreTitular, numeroTarjeta, expiracion, cvv }: CreditCardInformation, myIp: string) {

    return {
      payload: {
        nombre: nombreTitular,
        numTarjeta: numeroTarjeta.replaceAll(' ', '')!,
        cvv,
        fechaVencimiento: expiracion.replaceAll('/', '')!,
        region: this.#region(),
        precio: this.phone.pagoInicial!,
        productoCode: this.phone.codigo,
        descripcion: this.phone.descripcion,
        cantidad: 1,
        color: this.phone.field_color,
        capacidad: this.phone.field_capacidad_memoria_interna_,
        payShema: "PRE",
        planType: "Telcel Max Sin Límite",
        ipAdd: myIp,
        numPlazos: this.#cuotas().length.toString(),
        numCuenta: this.#externalId()!,
        orgId: this.#tagGetProperties()?.orgId,
        sessionId: this.#tagGetProperties()?.sessionId,
        sessionKey: this.#tagGetProperties()?.sessionKey,
      }
    }
  }

  private generateNoteRequest() {
    const precioConIva = Number(this.phone.precio_iva).toFixed(2);
    const precioSinIva = (Number(precioConIva) / 1.16).toFixed(2);

    const engancheConIva: number | string = this.phone.pagoInicial!;
    const engancheSinIva = (Number(engancheConIva) / 1.16).toFixed(2);

    return {
      payload: {
        sku: this.phone.codigo,
        node_id: this.phone.node_id, // Es el node_id del telefono seleccionado gracias al motorCalculo
        almacen: this.#oficinaID(),
        observacion: this.#putSolicitud()?.clavePago,// Viene por el servicio del motorCalculo clavePago
        region: this.#region(),
        marca: this.phone.marca,
        modelo: this.phone.modelo,
        precio: precioConIva,
        precio_sin_iva: precioSinIva,
        sobreprecio: null, // Viene estatico
        pago_inicial: engancheSinIva, // Enganche sin IVA
        cantidad: "1",
        id_reservacion: this.#idReservacion(), // Es la respuesta del servicio de reservaREcurso reservarRecursoResponse.idReservacion
        serie: this.#serie(), // Es la serie del telefono seleccionado gracias al motorCalculo
        agente: `${this.#idEmpleado()}|${this.#agente()}`, // INformación de telcel
        tipo_pago: "99",//por default
        codigo_impuesto: "S01",//por default
        cuenta: this.#externalId(), // Es el external_id de la respuesta al servicio de solictudCuenta
        numeroOrden: `AP-${this.#externalId()}-${Math.floor(Math.random() * 1001)}`,
        total_descuento: null,
        periodo_contrato: null,
        periodo_financiamento: null,
        descuento_con_iva: null,
        descuento_sin_iva: null,
        iva: "16",
        motivo: "M68",//por default
        tipo_activacion: null,
        tipo_descuento: null
      }
    }
  }

  private obtenerContratoRequest() {
    const saldoPendiente = this.#cuotas()
      .map(c => Number(c.monto))
      .reduce((current, previous) => current + previous)
      .toFixed(2);

    return {
      payload: {
        cuenta: this.#externalId(),
        linea: this.#clientPhone(),
        pagoInicial: this.phone.pagoInicial,
        saldoPendiente,
        cat: 'cat',
        parcialidades: this.#cuotas().length,
        periodicidad: this.#pagosPlazos().periodicidad,
        monto: this.#cuotas()[0].monto,
        fechaCorte: this.#cuotas()[this.#cuotas().length - 1].vencimiento,
        fechaPrimerPago: this.#cuotas()[0].vencimiento,
        fzaVenta: this.#fuerzaVenta(),
        contrato: null,
        idTransaccion: this.#idTransaccion(),
      }
    }
  }

  private generateContractRequest() {
    return {
      payload: {
        contrato: null,
        cuenta: this.#externalId(),
        idTransaccion: this.#idTransaccion(),
      }
    }
  }

  private getDomicilio() {
    const tipoEntrega = this.#tipoEntregaSeleccionada();

    if (tipoEntrega === 'domicilio' || tipoEntrega === 'tienda') {
      return {
        numExterior: this.datosEnvio.numeroExterior,
        colonia: this.datosEnvio.colonia,
        municipio: this.datosEnvio.municipio,
        ciudad: this.datosEnvio.estado,
      }
    } else {
      return {
        numExterior: '',
        colonia: '',
        municipio: '',
        ciudad: '',
      }
    }
  }

  private mapTipoPago() {
    let tipoPago: 'Conekta' | 'Tarjeta' | 'Sicatel' = 'Sicatel';

    switch (this.#tipoPago()) {
      case 'efectivo':
        return tipoPago = 'Conekta';
      case 'tarjeta':
      case 'Enganche Cero':
        return tipoPago = 'Tarjeta'
      default:
        return tipoPago = 'Sicatel';
    }
  }

  private enviarCredencialesRequest() {
    return {
      payload: {
        numCuenta: this.#externalId(),
        idTransaccion: this.#idTransaccion(),
      }
    }
  }
}
