<p-dialog
  [visible]="showDialog"
  [closable]="false"
  [closeOnEscape]="false"
  [modal]="true"
  [style]="{ width: '95vw', maxWidth: '500px' }"
  [baseZIndex]="10000"
>
  <ng-template pTemplate="header">
    <h3>Hubo un cambio en tus pagos</h3>
  </ng-template>
  <p class="mb-4">
    Tu enganche sufrió un cambio para pagar un monto de
    <strong>{{ getValues.enganche| currency }}</strong> y tus cuotas
    <em>{{ getValues.plazos }}</em> ahora serán de <strong>{{ getValues.monto| currency }}</strong>
  </p>
  <ul class="list-none">
    <li><strong>Total de pagos:</strong> {{ getValues.totalCuotas }}</li>
    <li><strong>Fecha de la primera cuota: </strong> {{ getValues.primeraCuota }}</li>
    <li><strong>Fecha de la última cuota: </strong> {{ getValues.ultimaCuota }}</li>
  </ul>
  <p></p>
  <ng-template pTemplate="footer">
    <button class="p-button-danger" type="button" pButton (click)="onCancel()">Cancelar</button>
    <button type="button" pButton (click)="onSubmit()">Aceptar</button>
  </ng-template>
</p-dialog>
<p-toast key="genericToast" [breakpoints]="{'920px': {width: '100%', right: '0', left: '0'}}">
</p-toast>
