<p-dialog [modal]="true" [visible]="visible()" [closable]="false" [style]="{ width: '90%', maxWidth: '500px' }"
  [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }">
  <ng-template pTemplate="header">
    <div class="title-modal p-5 text-center">
      {{title()}}
    </div>
  </ng-template>
  <ng-template pTemplate="content">
    <p class="mb-0 text-center text-2xl">{{ message() }}</p>
  </ng-template>
  <ng-template pTemplate="footer">
    <div class="py-3 flex justify-content-center column-gap-2">
      <button type="button" class="bg-danger justify-content-center w-10rem" severity="danger" pButton (click)="cancel()">
        No
      </button>
      <button type="button" class="justify-content-center w-10rem" pButton (click)="confirm()">
        Si
      </button>
    </div>
  </ng-template>
</p-dialog>
