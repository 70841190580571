import { NgClass } from '@angular/common';
import { Component, input, output } from '@angular/core';
import { ButtonModule } from 'primeng/button';

@Component({
  selector: 'app-button',
  standalone: true,
  imports: [
    NgClass,
    ButtonModule
  ],
  templateUrl: './button.component.html',
  styleUrl: './button.component.scss'
})
export class ButtonComponent {
  label = input.required<String>();
  type = input<'button' | 'submit'>('button');
  disabled = input(false);
  classes = input<string>();
  clicking = output<boolean>({alias: 'click'});

  onClick() {
    this.clicking.emit(true);
  }
}
