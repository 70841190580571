import { createReducer, on } from "@ngrx/store";

import { PaymentAttributesInterface } from "../../interfaces";
import { INIT_PAYMENT_STATE } from "../../utilities";
import { saveCardType, saveLastCardNumbers, savePayment, savePaymentType, savePutSolicitud, saveSessionTagGetProperties } from "./payment.actions";


const paymentInitialState = INIT_PAYMENT_STATE;

export const PaymentAttributesReducer = createReducer(
  paymentInitialState,
  on(
    savePayment,
    (state, { value }): PaymentAttributesInterface => ({ ...value })
  ),

  on(
    savePutSolicitud,
    (state, { value }): PaymentAttributesInterface => ({ ...state, putSolicitud: value })
  ),

  on(
    savePaymentType,
    (state, { value }): PaymentAttributesInterface => ({ ...state, tipoPago: value })
  ),

  on(
    saveSessionTagGetProperties,
    (state, { sessionId, orgId, sessionKey }): PaymentAttributesInterface => ({ ...state, tagGetProperties: { sessionId, orgId, sessionKey } })
  ),

  on(
    saveLastCardNumbers,
    (state, { value }): PaymentAttributesInterface => ({ ...state, lastCardNumbers: value })
  ),

  on(
    saveCardType,
    (state, { value }): PaymentAttributesInterface => ({ ...state, cardType: value })
  )
);
