import { ChangeDetectionStrategy, Component, inject, input, output, signal } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';

@Component({
  selector: 'app-confirm-dialog',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ConfirmDialogModule,
    DialogModule,
    ButtonModule,
  ],
  templateUrl: './confirm-dialog.component.html',
  styleUrl: './confirm-dialog.component.scss'
})
export class ConfirmDialogComponent {
  #confirmationService = inject(ConfirmationService);

  position = input<string>('bottom');
  title = input<string>('');
  message = input<string>('');
  visible = input<boolean>(false);

  onConfirm = output<boolean>();
  onCancel = output<boolean>();


  confirm() {
    this.onConfirm.emit(true);
    this.#confirmationService.close();
  }

  cancel() {
    this.onCancel.emit(true);
    this.#confirmationService.close();
  }
}
