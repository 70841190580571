import { PaymentAttributesInterface } from "@store/interfaces";

export const INIT_PAYMENT_STATE: PaymentAttributesInterface = {
  nro_cuotas: 0,
  periodicidad: '',
  aportacion_voluntaria: 0,
  sarasa: '',
  enganche: 0,
  factor: 0,
  producto: {
    sku: '',
    nombre: '',
    marca: '',
    modelo: '',
    precio: '',
  },
  cuotas: []
}
