import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';

import { rfcPattern } from '@cliente/helpers';
import { ClienteService } from '@cliente/services';
import { GenerarNoteErrors, ModalType } from '@entrega-equipo/interfaces';
import { generateGenerarNotaBody } from '@pago/helpers';
import { PagoService, PaymentRecalculationsService } from '@pago/services';
import { Store } from '@ngrx/store';
import { selectClientAttributesFeatures } from '@store/index';

@Component({
  selector: 'app-rfcmodal',
  templateUrl: './rfcmodal.component.html',
  styleUrls: ['./rfcmodal.component.scss'],
  standalone: true,
  imports: [DialogModule, FormsModule, ReactiveFormsModule, InputTextModule, ButtonModule]
})
export class RFCModalComponent implements OnInit {
  @Input() showDialog = false;
  @Output() generarNotaWasCalledCorrectly = new EventEmitter<boolean>(false);

  readonly #formBuilder = inject(FormBuilder);
  readonly #store = inject(Store);
  readonly #clienteService = inject(ClienteService);
  readonly #pagoService = inject(PagoService);
  readonly #paymentRecalculation = inject(PaymentRecalculationsService);

  readonly #clientData = this.#store.selectSignal(selectClientAttributesFeatures);

  rfcForm = this.#formBuilder.group({
    rfc: ['', [Validators.required, Validators.minLength(13), Validators.maxLength(13), Validators.pattern(rfcPattern)]],
  });

  get clientData() {
    return this.#clientData()!;
  }


  ngOnInit(): void {
    this.setRFC();
  }

  onSubmit() {
    if (this.rfcForm.invalid) {
      this.rfcForm.markAllAsTouched();
      return;
    }

    this.clientData.rfc = this.rfcForm!.get('rfc')!.value!;
    this.#clienteService.sendDatosCliente(this.clientData).subscribe({
      next: () => this.callGenerateNote()
    })
  }


  private setRFC() {
    const { rfc } = this.clientData
    this.rfcForm.get('rfc')?.setValue(rfc);
    this.rfcForm.setErrors({ isTheSameRFC: true });
  }

  private callGenerateNote() {
    const payloadGenerarBody = this.payloadGenerarBody();
    this.#pagoService.generateNote(payloadGenerarBody).subscribe({
      next: () => this.generarNotaWasCalledCorrectly.emit(true),
      error: (error: Error) => {
        const { message } = error;
        const auxMessage = message.toUpperCase();

        if (auxMessage.includes(GenerarNoteErrors.BESMayorSAP)) {
          const recalcularEnganche = this.#paymentRecalculation.recalcularEngancheYCuotas(auxMessage);

          if (recalcularEnganche) {
            sessionStorage.setItem('showModal', ModalType.recalculateDownPayment);
          } else {
            sessionStorage.setItem('showModal', ModalType.phoneNotAvailable);
          }
        } else if (auxMessage.includes(GenerarNoteErrors.RFCError)) {
          sessionStorage.setItem('showModal', ModalType.rfc);
        } else {
          sessionStorage.setItem('showModal', ModalType.phoneNotAvailable);
        }

        location.reload();
      }
    });
  }

  private payloadGenerarBody() {
    const getGenerarNotaBody = generateGenerarNotaBody();
    return getGenerarNotaBody.generarNota.body.payload;
  }
}
