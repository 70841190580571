
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, computed, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute, ActivationEnd, Params, Router, RouterOutlet } from '@angular/router';
import { Store } from '@ngrx/store';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { concatMap, filter, map, take, tap } from 'rxjs';

import { GlobalVarService } from '@core/services';
import { ErrorModalComponent, SpinnerComponent } from '@shared/components';
import { enableComputerUse, selectDesktopUse } from './store';
/**
 * Componenten principal donde se aloja el router-outlet
 ** Creado por: Jesus Martínez Torres
 ** Fecha: 10/04/2023
 */
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    RouterOutlet,
    NgxUiLoaderModule,
    SpinnerComponent,
    ErrorModalComponent,
  ],
})
export class AppComponent implements OnInit {
  readonly #globalVarService = inject(GlobalVarService);
  readonly #deviceService = inject(DeviceDetectorService);
  readonly #activatedRouter = inject(ActivatedRoute);
  readonly #cdr = inject(ChangeDetectorRef);
  readonly #store = inject(Store);
  readonly #router = inject(Router);

  isDesktop: boolean = false;
  allowDesktopUse = computed(() => this.#globalVarService.isPhone());
  showOutlet = computed(() => this.#globalVarService.showOutlet());
  loadingText = computed(() => this.#globalVarService.loadingText());
  #desktopUse = toSignal(this.#store.select(selectDesktopUse));


  constructor() {
    this.isDesktop = this.#deviceService.isDesktop();
    this.#cdr.markForCheck();
  }

  ngOnInit(): void {
    this.#router.events
      .pipe(
        filter((event) => event instanceof ActivationEnd),
        map((response) => {
          if (response instanceof ActivationEnd) {
            return response.snapshot.title
          }
          return ''
        }),
        take(1),
        tap((response) => {
          if (response !== 'Página de Bienvenida') {
            this.#globalVarService.isPhone.set(this.#desktopUse()!)
          }
        }),
        filter((response) => response === 'Página de Bienvenida'),
        concatMap(() => this.#activatedRouter.queryParams
          .pipe(
            filter(params => params && Object.keys(params).length > 0)
          ))
      )
      .subscribe({
        next: (response) => this.checkDesktopUse(response)
      })

  }


  private checkDesktopUse(response: Params) {
    const url = this.#router.url.split('?');
    if (url[0] === '/') {
      this.#store.dispatch(enableComputerUse({ computerUse: response['desktop'] !== undefined }));
      this.#globalVarService.isPhone.set(response['desktop'] !== undefined)
    }
  }
}
