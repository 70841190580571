import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject, signal } from '@angular/core';
import { Store } from '@ngrx/store';
import { catchError, map, retry } from 'rxjs';

import { ServicesNames } from '@core/enums';
import { environment } from '@envs/environment';
import { selectIdFlujo, selectNivelRiesgo } from '@store/index';
import { manejarError, superUrl } from '../helpers';
import { GetSettingsInterface } from '../interfaces';


@Injectable({
  providedIn: 'root'
})
export class GlobalVarService {
  readonly #http = inject(HttpClient);
  readonly #store = inject(Store);

  showOutlet = signal(true);
  isPhone = signal(false);
  loadingText = signal('');
  warningToastText = signal('');

  #showErrorModal = signal(false);
  #errorModalMessage = signal('');

  #showSpinner = signal(false);
  #backButtonHeader = signal<boolean>(false);

  #getIdFlujo = this.#store.selectSignal(selectIdFlujo);
  #getNivelRiesgo = this.#store.selectSignal(selectNivelRiesgo);


  get showSpinner() {
    return this.#showSpinner.asReadonly();
  }

  get showErrorModal() {
    return this.#showErrorModal.asReadonly();
  }

  get errorModalMessage() {
    return this.#errorModalMessage.asReadonly();
  }

  get url() {
    return superUrl
  }


  guardaPasos(idPantalla: number) {
    const headers = new HttpHeaders({
      'x-api-key': environment.apiKey_actualizaPasos
    });
    const body = {
      payload: {
        "idPantalla": idPantalla
      }
    }
    return this.#http.post<any>(
      `${this.url}/${ServicesNames.ACTUALIZAR_PAGO}`,
      body,
      { headers }
    ).pipe(
      retry(3),
      map((response) => {
        if (!response.process) throw response.message;
        return response
      }),
      catchError((error) => manejarError(error))
    );

  }

  getSettings() {
    const headers = new HttpHeaders({
      'x-api-key': environment.apiKey_settings
    });

    return this.#http.get<GetSettingsInterface>(`${this.url}/obtenerConfiguracionesFront?idFlujo=${this.#getIdFlujo()}&nivelRiesgo=${this.#getNivelRiesgo()}`,
      {
        headers
      },
    ).pipe(
      map((response) => response.responseObject)
    );
  }

  onClickHederButton(value = true) {
    this.#backButtonHeader.set(value);
  }

  onShowSpinner() {
    this.#showSpinner.set(true);
  }

  onCloseSpinner() {
    this.#showSpinner.set(false);
  }

  onOpenErrorModal(value: string) {
    this.onSetErrorModal(true);
    this.onSetErrorMessageMessage(value);
  }

  onCloseErrorModal() {
    this.onSetErrorModal(false);
    this.onSetErrorMessageMessage('');
  }


  private onSetErrorModal(value: boolean) {
    this.#showErrorModal.set(value);
  }

  private onSetErrorMessageMessage(value: string) {
    this.#errorModalMessage.set(value);
  }
}
