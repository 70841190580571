import { Injectable } from '@angular/core';

import { PageLoadEvent } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class AdobeTagManagerService {

  sendPageLoadEvent({seccion, pageName, tipoOTP = 'SMS', region = 'Region No Identificada'}: Partial<PageLoadEvent>) {
    this.sendEvent({
      event: 'pageLoad',
      pagina: {
        canal: 'Amigo Paguitos',
        seccion,
        pageName
      },
      usuario: {
        tipoOTP,
        region,
        perfil: 'NOCLIENT'
      }
    });
  }


  sendEvent(event: any) {
    console.log({event});

    try {
      const dataLayer = (window as any).dlMetri;
      if (dataLayer && typeof (dataLayer['push']) == 'function') {
        dataLayer.push(event);
        console.log('Evento ADOBE construido correctamente');
        console.log(event);
      }
    } catch (error) {
      console.log('Hubo un error en ADOBE Tag Manager');
      console.log(error);
    }
  }
}
