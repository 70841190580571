import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, throwError } from 'rxjs';

import { amazon, api_key_tap_web, environment } from '@envs/environment';
import { ActualizarStatusClienteResponse, RegistrarDispositivoResponse, ReservaImeiResponse } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class ReservationService {

  private get getTokenAndPhone() {
    const body = {
      numeroCelular: sessionStorage.getItem('numeroCelular'),
      token: sessionStorage.getItem('tokenTelcel'),
    }

    return { ...body }
  }

  constructor( private http: HttpClient ) { }

  actualizarStatusCliente(status = 'Completado') {
    const body = {
      ...this.getTokenAndPhone,
      payload: {
        estatus: status
      }
    }

    return this.http.post<ActualizarStatusClienteResponse>(
      `${ amazon.apiUrlAmazon }/${ environment.ambiente }/actualizaEstatusCliente`,
      body,
      { headers: this.getHttpHeaders(environment.apiKey_actualizarStatusCliente) }
    ).pipe(
      map((response) => {
        if ( !response.process ) throw response.message;
        return response.message;
      }),
      catchError(() => {
        return throwError(() => ({
          actualizaEstatusCliente: {
            body: {
              payload: {
                estatus: status
              }
            }
          }
        }))
      })
    )
  }

  reservaIMEI() {
    const body = {
      ...this.getTokenAndPhone,
      payload: {
        numCuenta: sessionStorage.getItem('externalId'),
        imei: sessionStorage.getItem('serie'),
      }
    }

    return this.http.post<ReservaImeiResponse>(
      `${ amazon.apiUrlAmazon }/${ environment.ambiente }/reservaImei`,
      body,
      { headers: this.getHttpHeaders(environment.apiKey_reservaImei) }
    ).pipe(
      map((response) => {
        if ( !response.process ) throw response.message;
        return response.responseObject;
      }),
      catchError(() => {
        return throwError(() => ({
          reservaImei: {
            body: {
              payload: {
                ...body.payload
              }
            }
          }
        }))
      })
    );
  }

  registrarDispositivo() {
    const body = {
      ...this.getTokenAndPhone,
      payload: {
        numCuenta: sessionStorage.getItem('externalId'),
        imei: null
      }
    };

    return this.http.post<RegistrarDispositivoResponse>(
      `${ amazon.apiUrlAmazon }/${ environment.ambiente }/registrarDispositivo`,
      body,
      { headers: this.getHttpHeaders(environment.apiKey_registrarDispositivo) }
    ).pipe(
      map((response) => {
        if ( !response.process ) throw response.message;
        return response.responseObject;
      }),
      catchError(() => {
        return throwError(() => ({
          registrarDispositivo: {
            body: {
              payload: {
                ...body.payload
              }
            }
          }
        }))
      })
    );
  }


  private getHttpHeaders(apiKey = api_key_tap_web) {
    return new HttpHeaders({
      'x-api-key': apiKey
    });
  }
}
