import { FormArray, FormGroup } from '@angular/forms';
import moment from 'moment';
import RfcFacil from 'rfc-facil';

import { ClientData } from '../interfaces';


let dateToHomoclave = { day: 0, month: 0, year: 0};

export function loadClientForm(data: ClientData) {
  const fechaValida =  new RegExp('([0-9]{2,})([/])([0-9]{2,})([/])([0-9]{4,})');


  if (!fechaValida.test(data.fechaNacimiento)) {
    data.fechaNacimiento = '';

    const currentDate =  moment();
    dateToHomoclave = { day: currentDate.get('D'), month: currentDate.month() + 1, year: currentDate.year() }
  } else {
    const dateBirthdayFormat = moment(data.fechaNacimiento, 'DD/MM/YYYY');
    dateToHomoclave = { day: dateBirthdayFormat.get('D'), month: dateBirthdayFormat.month() + 1, year: dateBirthdayFormat.year() };
  }

  if (data.curp.length < 10) {
    data.curp = ''
  }

  data.rfc = RfcFacil.forNaturalPerson({
    name: data.nombre,
    firstLastName: data.apellidoPaterno,
    secondLastName: data.apellidoMaterno,
    day: dateToHomoclave.day,
    month: dateToHomoclave.month,
    year: dateToHomoclave.year
  });

  let numeroExterior = String(data.numeroExterior);
  if ( numeroExterior === '0' ) numeroExterior = '';

  let regimen: any = data.regimen;

  if ( data.regimen && typeof data.regimen === 'object' ) {
    regimen = { id: Number(data.regimen[0].id), descripcion: data.regimen[0].descripcion };
  }

  data.referencias?.forEach((r) => {
    if (typeof r.parentesco === 'object') {
      const parentesco = { id: Number(r.parentesco[0].id), descripcion: r.parentesco[0].descripcion }
      r.parentesco = [parentesco]
    }
  })

  return {
    informacionPersonal: {
      nombres: data.nombre,
      aPaterno: data.apellidoPaterno,
      aMaterno: data.apellidoMaterno,
      fechaNacimiento: data.fechaNacimiento,
      curp: data.curp
    },
    datosFiscales: {
      rfc: data.rfc,
      regimen
    },
    domicilio: {
      cp: data.codigoPostal,
      calle: data.calle,
      numeroExterior,
      colonia: data.colonia,
      municipio: data.municipio,
      ciudad: data.ciudad
    },
    referenciasPersonales: {
      referencias: data.referencias || []
    }
  };
}


export function clientDataRequest(form: FormGroup): ClientData {
  const personalInformationForm = form.controls['informacionPersonal'] as FormGroup;
  const taxDataForm = form.controls['datosFiscales'] as FormGroup;
  const addressForm = form.controls['domicilio'] as FormGroup;
  const referencesForm = form.controls['referenciasPersonales'] as FormGroup;

  let birthdayDateString = '';

  const fechaValida =  new RegExp('([0-9]{2,})([/])([0-9]{2,})([/])([0-9]{4,})');

  if (!fechaValida.test(personalInformationForm.controls['fechaNacimiento'].value)) {
    const formatDate = moment(personalInformationForm.controls['fechaNacimiento'].value).format('DD/MM/YYYY');

    const dateParts = formatDate.split('/');
    birthdayDateString = `${dateParts[0]}/${dateParts[1]}/${dateParts[2]}`;

  } else {
    birthdayDateString = personalInformationForm.controls['fechaNacimiento'].value
  }


  let colonia = addressForm.controls['colonia'].value;
  if (typeof colonia === 'object') colonia = colonia.name;

  let municipio = addressForm.controls['municipio'].value;
  if (typeof municipio === 'object') municipio = municipio.name;

  let ciudad = addressForm.controls['ciudad'].value;
  if ( typeof ciudad === 'object' ) ciudad = ciudad.name;

  const getReferencias = referencesForm.controls['referencias'] as FormArray;
  const references = getReferencias.value.map((r: any) => {
    return {
      ...r,
      parentesco: [ r.parentesco ]
    }

  });


  return {
    nombre: personalInformationForm.controls['nombres'].value,
    apellidoPaterno: personalInformationForm.controls['aPaterno'].value,
    apellidoMaterno: personalInformationForm.controls['aMaterno'].value,
    fechaNacimiento: birthdayDateString,
    curp: personalInformationForm.controls['curp'].value,
    rfc: taxDataForm.controls['rfc'].value,
    regimen: [ taxDataForm.controls['regimen'].value ],
    codigoPostal: addressForm.controls['cp'].value,
    calle: addressForm.controls['calle'].value,
    numeroExterior: addressForm.controls['numeroExterior'].value,
    numeroInterior: 0,
    colonia,
    municipio,
    ciudad,
    referencias: references
}
}
