import { createSelector } from "@ngrx/store";
import { AddressAttributesInterface, AppState } from "../../interfaces";


export const selectAddressAttributesFeatures = (state: AppState) => state.address!;


export const selectDeliverySchedule = createSelector(
  selectAddressAttributesFeatures,
  (state: AddressAttributesInterface) => state.deliverySchedule
);

export const selectFolioReserva = createSelector(
  selectAddressAttributesFeatures,
  (state: AddressAttributesInterface) => state.folioReserva
);

export const selectTipoEntregaSeleccionada = createSelector(
  selectAddressAttributesFeatures,
  (state: AddressAttributesInterface) => state.tipoEntregaSeleccionada
);

export const selectIdHorario = createSelector(
  selectAddressAttributesFeatures,
  (state: AddressAttributesInterface) => state.idHorario
);

export const selectFolioEnvio = createSelector(
  selectAddressAttributesFeatures,
  (state: AddressAttributesInterface) => state.folioEnvio
);

export const selectDatosEnvio = createSelector(
  selectAddressAttributesFeatures,
  (state: AddressAttributesInterface) => state.datosEnvio
);

export const selectSucursal = createSelector(
  selectAddressAttributesFeatures,
  (state: AddressAttributesInterface) => state.sucursalSeleccionada
);

export const selectAddressSMT = createSelector(
  selectAddressAttributesFeatures,
  (state: AddressAttributesInterface) => state.direccion
);

export const selectNombrePaqueteria = createSelector(
  selectAddressAttributesFeatures,
  (state: AddressAttributesInterface) => state.nombrePaqueteria
);
