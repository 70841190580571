import { createReducer, on } from "@ngrx/store";

import { ClientAttributesInterface } from '../../interfaces';
import { saveClientInformation, saveIfClientContainsPhoneSaved, saveIfClientRecoverPhone, saveClientPhone, saveTokenEmailAndTrasactionId, saveClientEmail, saveClientInformationWithEffect, saveClientSeverityLevel, saveClientExternalID, initClientStorage, saveIccBccAndScoreEvaluador, saveIfClientDeletePhone } from './client.actions';
import { INIT_CLIENT_STATE } from "../../utilities";


export const clientInitialState: ClientAttributesInterface = INIT_CLIENT_STATE


export const ClientAttributesReducer = createReducer(
  clientInitialState,
  on(
    saveClientPhone,
    (state, { phone }): ClientAttributesInterface => ({ ...state, telefono: phone })
  ),

  on(
    initClientStorage,
    (): ClientAttributesInterface => (INIT_CLIENT_STATE)
  ),

  on(
    saveTokenEmailAndTrasactionId,
    (state, { email, idTransaction, token }): ClientAttributesInterface => ({ ...state, correoElectronico: email, idTransaccion: idTransaction, token })
  ),

  on(
    saveIfClientContainsPhoneSaved,
    (state, { value }): ClientAttributesInterface => ({ ...state, containsSavedPhone: value })
  ),

  on(
    saveIfClientRecoverPhone,
    (state, { value }): ClientAttributesInterface => ({ ...state, recoverPhone: value })
  ),

  on(
    saveIfClientDeletePhone,
    (state, { value }): ClientAttributesInterface => ({ ...state, deletePhone: value })
  ),

  on(
    saveClientInformation,
    saveClientInformationWithEffect,
    (state, { value }): ClientAttributesInterface => ({ ...state, ...value })
  ),

  on(
    saveClientEmail,
    (state, { value }): ClientAttributesInterface => ({ ...state, correoElectronico: value })
  ),

  on(
    saveClientSeverityLevel,
    (state, { value }): ClientAttributesInterface => ({ ...state, nivel_riesgo: value })
  ),

  on(
    saveClientExternalID,
    (state, { value }): ClientAttributesInterface => ({ ...state, externalID: value })
  ),

  on(
    saveIccBccAndScoreEvaluador,
    (state, { icc, bcc }): ClientAttributesInterface => ({ ...state, icc, bcc })
  ),
)
