import { createAction, props } from "@ngrx/store";

import { ClientAttributesInterface } from "../../interfaces";


export const saveClientInformation = createAction(
  '[Client]: Client Information Successfully Saved',
  props<{ value: Partial<ClientAttributesInterface> }>()
);

export const initClientStorage = createAction(
  '[Client]: Init Client State Success',
)


export const saveClientPhone = createAction(
  '[Client]: Client Phone Successfully Saved',
  props<{ phone: string }>()
);

export const saveTokenEmailAndTrasactionId = createAction(
  '[Client]: Email, Token And TransactionID Successfully Saved',
  props<{ email: string, idTransaction: string, token: string }>()
);

export const saveIfClientContainsPhoneSaved = createAction(
  '[Client]: existTelefono Successfully Saved',
  props<{ value: boolean }>()
);

export const saveIfClientRecoverPhone = createAction(
  '[Client]: Recovered Phone Successfully',
  props<{ value: boolean }>()
);

export const saveIfClientDeletePhone = createAction(
  '[Client]: Deleted Phone Successfully',
  props<{ value: boolean }>()
);

export const saveClientEmail = createAction(
  '[Client]: Email Successfully Saved',
  props<{ value: string }>()
);

export const saveClientInformationWithEffect = createAction(
  '[Client]: Client Information Successfully Saved',
  props<{ value: Partial<ClientAttributesInterface> }>()
);

export const callServicesToValidateEmailOTP = createAction(
  '[Client]: Validating Email OTP'
);

export const saveClientSeverityLevel = createAction(
  '[Client]: Severity Level Successfully Saved',
  props<{ value: number }>()
);

export const saveClientExternalID = createAction(
  '[Client]: ExternalID Successfully Saved',
  props<{ value: string }>()
);

export const saveIccBccAndScoreEvaluador = createAction(
  '[Client]: ICC, BCC and scoreEvaluador Saved',
  props<{icc: string, bcc: string}>()
);
