<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<div class="acc-header w-full" (click)="onToggle()">
  <div class="acc-header__title">
    <i
      class="pi pi-angle-right"
      [@rotatedState]="!showBody ? 'default' : 'rotated'"
    ></i>
    {{ title }}
  </div>
  <div class="acc-header__icon-container">
    <i [class]="icon"></i>
  </div>
</div>
<div
  class="acc-body w-full"
  [@smoothCollapse]="!showBody ? 'initial' : 'final'"
>
  <ng-content></ng-content>
</div>
