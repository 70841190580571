import { HttpInterceptorFn, HttpRequest } from "@angular/common/http";
import { inject } from "@angular/core";
import { ServicesNames } from "@core/enums";
import { Store } from "@ngrx/store";

import { selectIdEmpleado, selectIdTransaction } from "@store/index";



export const updateRequestBodyInterceptor: HttpInterceptorFn = (req, next) => {
  const clonedRequest = req.clone();
  if (req.method === 'GET' || notUpdatePayloadInServices(clonedRequest)) return next(clonedRequest);

  const store = inject(Store);


  const getBody: any = clonedRequest.body || {};

  let getIdTransaction = '';
  let getIdEmpleado = '';

  store.select(selectIdTransaction).subscribe((value) => getIdTransaction = value!);
  store.select(selectIdEmpleado).subscribe((value) => getIdEmpleado = value);

  let body = {
    ...getBody,
    idTransaccion: getIdTransaction,
    payload: {
      ...getBody?.payload,
      idTransaccion: getIdTransaction,
      agenteId: getIdEmpleado
    }
  }

  if (isPayloadStringType(clonedRequest)) {
    body = {
      ...getBody,
      idTransaccion: getIdTransaction,
      payload: ''
    }
  }

  const modifiedRequest = req.clone({
    body
  })

  return next(modifiedRequest);
}


function notUpdatePayloadInServices(request: HttpRequest<unknown>): boolean {
  const filteredRequests = [
    ServicesNames.VALIDAR_DATOS_URL,
    ServicesNames.REGISTRAR_CLIENTE,
    ServicesNames.RETOMAR_TRAMITE_SMS,
    ServicesNames.GET_PREGUARDADO,

    ServicesNames.SED_VALIDA_OCR,
    ServicesNames.GET_CP,
    ServicesNames.GET_CATALOGOS,
    ServicesNames.GET_INFO_CLIENTE,
    ServicesNames.SEND_DATOS_CLIENTE,
    ServicesNames.RENAPO,
    ServicesNames.SCORE_TELCO,
    ServicesNames.REVISION_INE,
    ServicesNames.REGISTRAR_EMAIL,
    ServicesNames.VALIDAR_OTP_EMAIL,
    ServicesNames.SEND_OTP_VERIFICACION,

    ServicesNames.POST_PRESTAMO,
    ServicesNames.EMAIL_EDGE,

    ServicesNames.SESSION_TAG_GET,

    ServicesNames.ACTUALIZAR_PAGO,

    ServicesNames.EVALUADOR,
    ServicesNames.SEVERITY_LEVEL,
    ServicesNames.MOTOR_CALCULO,
    ServicesNames.API_DESCUENTOS,
    ServicesNames.IMEI_CHECK
  ];

  return filteredRequests.some(serviceName => request.url.includes(serviceName));
}

function isPayloadStringType(request: HttpRequest<unknown>): boolean {
  const filteredRequest = [
    ServicesNames.GET_PREGUARDADO,
    'getEnvioDomicilio',
    ServicesNames.GET_SOLICITUD,
    'getLogLambda',
    'refreshToken',
    'terminaFlujo'
  ];

  const urlRequest = request.url;

  for (let i = 0; i < filteredRequest.length; i++) {
    if (urlRequest.includes(filteredRequest[i])) {
      return true;
    }
  }

  return false;
}
