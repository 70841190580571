import { createSelector } from "@ngrx/store";

import { GlobalAttributesInterface, AppState } from "../../interfaces";


export const selectGlobalAttributesFeatures = (state: AppState) => state.global;

export const selectDesktopUse = createSelector(
  selectGlobalAttributesFeatures,
  (state: GlobalAttributesInterface) => state.useDesktop
);

export const selectInitialURL = createSelector(
  selectGlobalAttributesFeatures,
  (state: GlobalAttributesInterface) => state.initialUrl
);

export const selectUrlSaved = createSelector(
  selectGlobalAttributesFeatures,
  (state: GlobalAttributesInterface) => state.preSavedUrl
)

export const selectIdEmpleado = createSelector(
  selectGlobalAttributesFeatures,
  (state: GlobalAttributesInterface) => state.idEmpleado
);

export const selectIdFlujo = createSelector(
  selectGlobalAttributesFeatures,
  (state: GlobalAttributesInterface) => state.idFlujo
);

export const selectTokenExpressID = createSelector(
  selectGlobalAttributesFeatures,
  (state: GlobalAttributesInterface) => state.tokenExpressID
);

export const selectValidationID = createSelector(
  selectGlobalAttributesFeatures,
  (state: GlobalAttributesInterface) => state.validationID
);

export const selectRegion = createSelector(
  selectGlobalAttributesFeatures,
  (state: GlobalAttributesInterface) => state.region
);

export const selectFlujo = createSelector(
  selectGlobalAttributesFeatures,
  (state: GlobalAttributesInterface) => state.flujo
);


export const selectCanal = createSelector(
  selectGlobalAttributesFeatures,
  (state: GlobalAttributesInterface) => state.canal
);

export const selectAgenteDAT = createSelector(
  selectGlobalAttributesFeatures,
  (state: GlobalAttributesInterface) => state.agenteDAT
);

export const selectAgente = createSelector(
  selectGlobalAttributesFeatures,
  (state: GlobalAttributesInterface) => state.agente
);

export const selectOficinaID = createSelector(
  selectGlobalAttributesFeatures,
  (state: GlobalAttributesInterface) => state.oficinaId
);

export const selectFuerzaVenta = createSelector(
  selectGlobalAttributesFeatures,
  (state: GlobalAttributesInterface) => state.fuerzaVenta
);

export const selectClaveOficinaAlmacen = createSelector(
  selectGlobalAttributesFeatures,
  (state: GlobalAttributesInterface) => state.claveOficinaAlmacen
);

export const selectClaveFuerzaVenta = createSelector(
  selectGlobalAttributesFeatures,
  (state: GlobalAttributesInterface) => state.claveFuerzaVenta
);

export const selectIdCanal = createSelector(
  selectGlobalAttributesFeatures,
  (state: GlobalAttributesInterface) => state.idCanal
);

export const selectOficinaDescripcion = createSelector(
  selectGlobalAttributesFeatures,
  (state: GlobalAttributesInterface) => state.oficinaDescripcion
);
