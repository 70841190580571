import { createSelector } from "@ngrx/store";
import { AppState, PhoneAttributesInterface } from "../../interfaces";


export const selectPhoneAttributesFeature = (state: AppState) => state.phone;

export const selectPhones = createSelector(
  selectPhoneAttributesFeature,
  (state: PhoneAttributesInterface) => state.phones
);

export const selectCreditLimit = createSelector(
  selectPhoneAttributesFeature,
  (state: PhoneAttributesInterface) => state.creditLimit
);

export const selectPhone = createSelector(
  selectPhoneAttributesFeature,
  (state: PhoneAttributesInterface) => state.phone
);

export const selectPhonesWithPromotionCode = createSelector(
  selectPhoneAttributesFeature,
  (state: PhoneAttributesInterface) => state.phoneWithPromotionCode
);

export const selectPromotionCode = createSelector(
  selectPhoneAttributesFeature,
  (state: PhoneAttributesInterface) => state.promotionCode
);

export const selectIdReservacion = createSelector(
  selectPhoneAttributesFeature,
  (state: PhoneAttributesInterface) => state.idReservacion
);

export const selectSeriePhone = createSelector(
  selectPhoneAttributesFeature,
  (state: PhoneAttributesInterface) => state.serie
);

export const selectInactiveRegion = createSelector(
  selectPhoneAttributesFeature,
  (state: PhoneAttributesInterface) => state.regionInactiva
);
