import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable, signal } from '@angular/core';
import { catchError, map } from 'rxjs/operators';

import { ServicesNames } from '@core/enums';
import { superUrl } from '@core/helpers';
import { environment } from '@envs/environment';
import { DeliveryTypesResponse, UrlDelivery } from '../interfaces';
@Injectable({
  providedIn: 'root'
})
export class DeliverySelectionService {
  readonly #http = inject(HttpClient);

  get url () {
    return superUrl;
  }


  getDeliveryTypes() {
    const body = {
      payload: {
        idTipoCliente: 1
      }
    }

    const headers = new HttpHeaders({
      'x-api-key': environment.apiKey_reservaRecurso
    });
    return this.#http.post<DeliveryTypesResponse>(
      `${this.url}/${ServicesNames.GET_ENVIO_EQUIPOS}`,
      body,
      { headers }
    ).pipe(
      map((response) => {
        if (!response.process) throw response.message;
        return response
      }),
      map((({ responseObject: { tipoEnvio } }) => tipoEnvio)),
      map((response) => {
        const getDeliveryTypes = response.filter(r => r.estatus);

        if (getDeliveryTypes.length === 2) {
          sessionStorage.setItem('tipoEntrega',
            JSON.stringify([UrlDelivery.homeDelivery, UrlDelivery.branchOffice]));
            return UrlDelivery.seleccion;
        }
        const { descripcion } = getDeliveryTypes[0];

        if (descripcion === 'ENTREGA_CAT') {
          sessionStorage.setItem('tipoEntrega', JSON.stringify([UrlDelivery.branchOffice]));
        } else {
          sessionStorage.setItem('tipoEntrega', JSON.stringify([UrlDelivery.homeDelivery]));
        }

        return UrlDelivery.seleccion;

      }),
      catchError(() => {
        sessionStorage.setItem('tipoEntrega',
          JSON.stringify([UrlDelivery.homeDelivery, UrlDelivery.branchOffice]));
        return UrlDelivery.seleccion;
      })
    )
  }
}
