import { ActionReducer } from "@ngrx/store";

import { AppState } from "../interfaces";
import { INIT_ADDRESS_STATE, INIT_CLIENT_STATE, INIT_GLOBAL_STATE, INIT_PAYMENT_STATE, INIT_PHONE_STATE } from "../utilities";


export const RESET_STATE = '[App] Reset State';

export function resetStateMetaReducer(reducer: ActionReducer<AppState>): ActionReducer<AppState> {
  return (state, action) => {
    if (action.type === RESET_STATE) {
      return {
        global: INIT_GLOBAL_STATE,
        client: INIT_CLIENT_STATE,
        phone: INIT_PHONE_STATE,
        payment: INIT_PAYMENT_STATE,
        address: INIT_ADDRESS_STATE,
      } as AppState;
    }
    return reducer(state, action);
  };
}
