import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-accordion-item',
  standalone: true,
  imports: [

  ],
  templateUrl: './accordion-item.component.html',
  styleUrl: './accordion-item.component.scss',
  animations:[
    trigger('smoothCollapse', [
      state('initial', style({
        height: '0',
        padding: '0',
        lineHeight: '0',
        borderBottom: 'none',
        overflow: 'hidden',
        opactity: '0',
        visibility: 'hidden'
      })),
      state('final', style({
        overflow: 'visible',
      })),
      transition('initial<=>final', animate('250ms')),
    ]),
    trigger('rotatedState', [
      state('default', style({
        transform: 'rotate(0)'
      })),
      state('rotated', style({
        transform: 'rotate(90deg)'
      })),
      transition('default<=>rotated', animate('250ms'))
    ])
  ]
})
export class AccordionItemComponent {
  @Input() title = '';
  @Input() icon = '';
  @Input() showBody = false;
  @Output() toggle = new EventEmitter<boolean>(this.showBody);
  @Output() openTab = new EventEmitter<boolean>(false);

  onToggle() {
    this.showBody = !this.showBody;
    this.toggle.emit(this.showBody);

    if (this.showBody) {
      this.openTab.emit(this.showBody)
    }
  }

}
