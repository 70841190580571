import { createReducer, on } from "@ngrx/store";

import { PhoneAttributesInterface } from "../../interfaces";
import { INIT_PHONE_STATE } from "../../utilities";

import { AddingPhonesWithPromitionCode, initPhone, initPromotionCode, saveCreditLimit, saveInactiveRegion, savePhone, savePhones, savePromotionCode, saveReservationIDAndSerie, saveSeriePhone } from "./phone.actions";


const phoneInitialState = INIT_PHONE_STATE;


export const PhoneAttributeReducer = createReducer(
  phoneInitialState,
  on(
    savePhones,
    (state, { value }): PhoneAttributesInterface => ({...state, phones: value, phoneWithPromotionCode: value })
  ),

  on(
    saveInactiveRegion,
    (state, { value }): PhoneAttributesInterface => ({...state, regionInactiva: value })
  ),

  on(
    saveCreditLimit,
    (state, { value }): PhoneAttributesInterface => ({...state, creditLimit: value })
  ),

  on(
    savePromotionCode,
    (state, { code, value }): PhoneAttributesInterface => ({...state, promotionCode: { code, value } })
  ),

  on(
    initPromotionCode,
    (state): PhoneAttributesInterface => ({...state, promotionCode: undefined })
  ),

  on(
    AddingPhonesWithPromitionCode,
    (state, { value }): PhoneAttributesInterface => ({...state, phoneWithPromotionCode: value })
  ),

  on(
    saveReservationIDAndSerie,
    (state, { reservationId, serie }): PhoneAttributesInterface => ({...state, idReservacion: reservationId, serie })
  ),

  on(
    saveSeriePhone,
    (state, { value }): PhoneAttributesInterface => ({...state, serie: value })
  ),

  on(
    savePhone,
    (state, { value }): PhoneAttributesInterface => ({...state, phone: value })
  ),

  on(
    initPhone,
    (state): PhoneAttributesInterface    => ({...state, phone: undefined })
  )
);
