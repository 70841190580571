import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { UrlDelivery } from '@entrega-equipo/interfaces';
import { Store } from '@ngrx/store';

import { selectCanal, selectInitialURL, selectPhone, selectTipoEntregaSeleccionada } from '@store/index';

export const engancheCeroGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const store = inject(Store);

  const phone = store.selectSignal(selectPhone);
  const channel = store.selectSignal(selectCanal);
  const selectedDeliveryType = store.selectSignal(selectTipoEntregaSeleccionada);
  const initialURL = store.selectSignal(selectInitialURL);

  if (phone() && phone()?.pagoInicial === 0) {
    return true;
  }

  const defaultRedirect = initialURL() || '/';
  const deliveryRedirect =
    selectedDeliveryType() === 'domicilio' ? UrlDelivery.homeDelivery : UrlDelivery.branchOffice;


  if (channel() !== 'CAT') {
    return router.navigateByUrl('/detalle-producto');
  } else if (selectedDeliveryType()) {
    return router.navigateByUrl(deliveryRedirect);
  }

  return router.navigateByUrl(defaultRedirect)
};
