import { createSelector } from "@ngrx/store";

import { ClientAttributesInterface, AppState } from "../../interfaces";


export const selectClientAttributesFeatures = (state: AppState) => state.client;

export const selectFullNameClient = createSelector(
  selectClientAttributesFeatures,
  (state: ClientAttributesInterface) => `${state.nombre} ${state.apellidoPaterno} ${state.apellidoMaterno}`
);

export const selectClientPhone = createSelector(
  selectClientAttributesFeatures,
  (state: ClientAttributesInterface) => state.telefono
);

export const selectEmail = createSelector(
  selectClientAttributesFeatures,
  (state: ClientAttributesInterface) => state.correoElectronico
);

export const selectToken = createSelector(
  selectClientAttributesFeatures,
  (state: ClientAttributesInterface) => state.token
);

export const selectIdTransaction = createSelector(
  selectClientAttributesFeatures,
  (state: ClientAttributesInterface) => state.idTransaccion
)

export const selectContainsSavedPhone = createSelector(
  selectClientAttributesFeatures,
  (state: ClientAttributesInterface) => state.containsSavedPhone
)

export const selectRecoverPhone = createSelector(
  selectClientAttributesFeatures,
  (state: ClientAttributesInterface) => state.recoverPhone
)

export const selectDeletePhone = createSelector(
  selectClientAttributesFeatures,
  (state: ClientAttributesInterface) => state.deletePhone
)

export const selectExternalID = createSelector(
  selectClientAttributesFeatures,
  (state: ClientAttributesInterface) => state.externalID
);

export const selectNivelRiesgo = createSelector(
  selectClientAttributesFeatures,
  (state: ClientAttributesInterface) => state.nivel_riesgo
);

export const selectExistReferences = createSelector(
  selectClientAttributesFeatures,
  (state: ClientAttributesInterface) => state.existeReferencia
);
