<p-dialog styleClass="mx-auto w-9 max-width-500" [closable]="false" [modal]="true" [visible]="show()">
  <ng-template pTemplate="headless">
    <section class="bg-principal relative h-full text-center p-6">
      <header class="mb-7">
        <div class="mb-4">
          <img class="img-icon" src="/assets/images/icons/tiempo.png" alt="Se te acaba el tiempo">
        </div>
        <h1 class="title font-bold mb-2">Opción no disponible</h1>
        <div class="decoration"></div>
      </header>
      <div>
        <p class="paragraph mb-6" [innerHTML]="message()"></p>
        <button type="button" class="block mx-auto w-9 sm:w-6 justify-content-center" pButton
          (click)="onAccept()">Aceptar</button>
      </div>
    </section>
  </ng-template>
</p-dialog>
