import { Component } from '@angular/core';

@Component({
    selector: 'app-content-main',
    templateUrl: './content-main.component.html',
    styleUrls: ['./content-main.component.scss'],
    standalone: true
})
export class ContentMainComponent {
}
