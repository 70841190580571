import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable, signal, WritableSignal } from '@angular/core';
import { catchError, map, Observable } from 'rxjs';

import { ServicesNames } from '@core/enums';
import { manejarError, superUrl } from '@core/helpers';
import { environment } from '@envs/environment';
import { RegistrarClienteRequest, RegistrarClienteResponse, ResponseObject } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class RegistroService {
  private readonly http = inject(HttpClient);

  #tipoVerificacion: WritableSignal<null | number> = signal(null);
  #statusCliente: WritableSignal<'USUARIO REGISTRADO' | 'USUARIO NUEVO' | 'INICIAL'> = signal('INICIAL');

  get url() {
    return superUrl;
  }

  get tipoVerificacion() {
    return this.#tipoVerificacion.asReadonly();
  }

  get statusCliente() {
    return this.#statusCliente.asReadonly();
  }


  registerClient(obj: RegistrarClienteRequest): Observable<ResponseObject> {
    const headers = new HttpHeaders({
      'x-api-key': environment.apiKey_registrarCliente
    });

    return this.http.post<RegistrarClienteResponse>(
      `${ this.url }/${ ServicesNames.REGISTRAR_CLIENTE }`,
      obj,
      { headers }
    ).pipe(
      map( (response) => {
        if ( !response.process ) throw response.message;

        sessionStorage.setItem('tokenTelcel', response.responseObject.jwt);

        const {statusCliente, tipoVerificacion } = response.responseObject;
        this.#tipoVerificacion.set(tipoVerificacion);
        this.#statusCliente.set(statusCliente === 0 ? 'USUARIO NUEVO' : 'USUARIO REGISTRADO');

        return response.responseObject;
      }),
      catchError((error) => manejarError(error))
    );
  }


  setStatusCliente(value: "USUARIO REGISTRADO" | "USUARIO NUEVO" | "INICIAL") {
    this.#statusCliente.set(value);
  }

  setTipoVerificacion(value: number) {
    this.#tipoVerificacion.set(value);
  }
}
