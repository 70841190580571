import { ActionReducerMap } from "@ngrx/store";

import { AppState } from "./interfaces";
import { ClientAttributesReducer } from "./modules/client";
import { GlobalAttributesReducer } from "./modules/global";
import { PhoneAttributeReducer } from "./modules/phone";
import { PaymentAttributesReducer } from "./modules/payment/payment.reducers";
import { AddressAttributesReducer } from "./modules/address";


export const ROOT_REDUCER: ActionReducerMap<AppState> = {
  global: GlobalAttributesReducer,
  client: ClientAttributesReducer,
  phone: PhoneAttributeReducer,
  payment: PaymentAttributesReducer,
  address: AddressAttributesReducer
}
