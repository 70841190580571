import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import { ButtonModule } from 'primeng/button';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ButtonModule
  ]
})
export class HeaderComponent {
  backButton = output<boolean>();
  showIcon = input(false);


  onBackButton(): void {
    this.backButton.emit(true);
  }
}
