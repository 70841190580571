import { ClientAttributesInterface } from "../interfaces";


export const INIT_CLIENT_STATE: ClientAttributesInterface = {
  telefono: '',
  correoElectronico: '',
  idTransaccion: '',
  nombre: '',
  apellidoPaterno: '',
  apellidoMaterno: '',
  fechaNacimiento: '',
  curp: '',
  rfc: '',
  codigoPostal: '',
  calle: '',
  numeroExterior: 0,
  numeroInterior: 0,
  colonia: '',
  municipio: '',
  ciudad: '',
}
