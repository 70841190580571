<p-dialog [(visible)]="showDialog" [breakpoints]="{'960px': '75vw'}" [style]="{ height: '100vh', width: '95vw' }"
    [draggable]="false" [resizable]="false" [closable]="false">
    <p class="text-red-500 font-bold text-center">Hubo un problema con tu RFC, por favor actualícelo.</p>
    <form action="w-full" [formGroup]="rfcForm" (ngSubmit)="onSubmit()">
      <div class="field p-component app-text-blue mb-3">
        <label for="rfc" class="block text-md font-bold">RFC</label>
        <input type="text" pInputText id="rfc" class="w-full" formControlName="rfc" required maxlength="13" oninput="javascript:this.value=this.value.toUpperCase();">
      </div>
      <div class="flex justify-content-end">
        <p-button type="submit" label="Guardar"></p-button>
      </div>
    </form>
</p-dialog>
