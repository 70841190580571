import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject, signal } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, catchError, firstValueFrom, map } from 'rxjs';

import { ReferencesConfiguration } from '@cliente/enum';
import { ServicesNames } from '@core/enums';
import { manejarError, superUrl } from '@core/helpers';
import { GlobalVarService } from '@core/services';
import { environment, totalReferrals } from '@envs/environment';
import { saveClientInformation, saveValidationID, selectIdTransaction, selectClientPhone, selectRegion, selectToken, selectValidationID } from '@store/index';
import { Catalogo, CatalogsResponse, ClientData, DropdownObject, GetCPResponse, Ine404Response, IneSuccesResponse, InfoClientResponse, ResponseList, RevisionIneResponseInterface, ScorePhoneResponse, responseRenapo } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class ClienteService {
  readonly #globalVarServices = inject(GlobalVarService);
  readonly #http = inject(HttpClient);
  readonly #store = inject(Store);

  verifycationRetryNumberVeridas = signal(0);

  readonly #getClientPhone = this.#store.selectSignal(selectClientPhone);
  readonly #getToken = this.#store.selectSignal(selectToken);
  readonly #validationID = this.#store.selectSignal(selectValidationID);
  readonly #getRegion = this.#store.selectSignal(selectRegion);
  readonly #getIdTransaction = this.#store.selectSignal(selectIdTransaction);
  readonly #maximumVeridasRetries = 100;


  get url() {
    return superUrl;
  }

  get getmaximumVeridasRetries() {
    return this.#maximumVeridasRetries;
  }


  getCities(postalCode: string) {
    const headers = new HttpHeaders({
      'x-api-key': environment.apiKey_getCP
    });

    const body = {
      payload: {
        codigo_postal: postalCode
      }
    }

    return this.#http.post<GetCPResponse>(
      `${this.url}/${ServicesNames.GET_CP}`,
      body,
      { headers }
    ).pipe(
      map((response) => {
        if (!response.process) throw response.message;
        const data = response.responseList;
        return this.mapCitiesResponse(data);
      }),
      catchError((error) => manejarError(error))
    );
  }

  getCatalogs(id: number): Observable<Catalogo[]> {
    const headers = new HttpHeaders({
      'x-api-key': environment.apiKey_getCatalogos
    });

    const body = {
      payload: {
        id: [id]
      }
    }

    return this.#http.post<CatalogsResponse>(
      `${ this.url }/${ServicesNames.GET_CATALOGOS}`,
      body,
      { headers }
    ).pipe(
      map((response) => {
        if (!response.process) throw new Error(response.message);
        return response.responseList;
      })
    );
  }

  getMockCatalogs(id: number) {
    return this.#http.get<{ responseList: Catalogo[] }>("../../../assets/mock/catalogos.json")
      .pipe(
        map((response) => {
          return response.responseList.filter(r => r.id_catalogo === id);
        })
      )
  }

  getInfoCliente(): Observable<ClientData> {
    const body = {
      payload: {
        existeReferencia: totalReferrals > 0,
        idTransaccion: this.#getIdTransaction()
      }
    }

    const headers = new HttpHeaders({
      'x-api-key': environment.apiKey_getInfoCliente
    });

    return this.#http.post<InfoClientResponse>(
      `${this.url}/${ServicesNames.GET_INFO_CLIENTE}`,
      body,
      { headers }
    ).pipe(
      map((response) => {
        if (!response.process) throw response.message;
        const dateParts = response.responseObject.fechaNacimiento.trim().split('-');
        response.responseObject.fechaNacimiento = `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;

        this.#store.dispatch(saveValidationID({ value: response.responseObject.validationId! }))
        return response.responseObject;
      }),
      catchError((error) => manejarError(error))
    );
  }

  validateOCR(): Observable<ClientData> {
    const headers = new HttpHeaders({
      'x-api-key': environment.apiKey_sendValidaOCR
    });

    const body = {
      numerocelular: this.#getClientPhone(),
      token: this.#getToken(),
      idTransaccion: this.#getIdTransaction(),
      validationId: this.#validationID()
    }

    return this.#http.post<InfoClientResponse>(
      `${ this.url }/${ ServicesNames.SED_VALIDA_OCR }`,
      body,
      { headers }
    ).pipe(
      map((response) => {
        if (!response.process || response.identifier === -1) throw response.message;
        return response.responseObject;
      }),
      catchError((error) => manejarError(error))
    );
  }

  sendDatosCliente(obj: ClientData): Observable<null> {
    const headers = new HttpHeaders({
      'x-api-key': environment.apiKey_sendDatosClientes
    });

    const parsedJSON = {
      payload: {
        ...obj,
        idTransaccion: this.#getIdTransaction()
      },
      numerocelular: this.#getClientPhone(),
      token: this.#getToken(),
      validationId: this.#validationID()
    }

    return this.#http.post<InfoClientResponse>(
      `${ this.url }/${ ServicesNames.SEND_DATOS_CLIENTE }`,
      parsedJSON,
      { headers }
    ).pipe(
      map((response) => {
        if (!response.process) throw response.message;

        this.#store.dispatch(saveClientInformation({ value: obj }));
        return null;
      }),
      catchError((error) => manejarError(error))
    )
  }

  scoreTelco(phone: string) {
    const headers = new HttpHeaders({
      'x-api-key': environment.apiKey_scoreTelco
    });

    const body = {
      payload: {
        numeroCelular: phone
      }
    };

    return this.#http.post<ScorePhoneResponse>(
      `${this.url}/${ ServicesNames.SCORE_TELCO }`,
      body,
      { headers }
    ).pipe(
      map((response) => {
        if (!response.process) throw response.message;
        return {
          code: Number(response.responseObject.detailResponse.code),
          scoreTelco: response.responseObject.scoreResponse.score_telco
        }
      }),
      catchError((error) => manejarError(error))
    );
  }

  validaCurp(curp: string) {
    const headers = new HttpHeaders({
      'x-api-key': environment.apiKey_sessionRenapo
    });
    const body = {
      payload: {
        curp
      }
    };
    return this.#http.post<responseRenapo>(
      `${this.url}/${ServicesNames.RENAPO}`,
      body,
      { headers }
    ).pipe(
      map((response) => {
        if (!response.process) throw response.message;
        return response.responseObject
      }),
      catchError((error) => manejarError(error))
    );
  }

  revisionIne() {
    const headers = new HttpHeaders({
      'x-api-key': environment.apiKey_revisionIne
    });

    const body = {
      payload: {
        region: this.#getRegion() || '9',
        idTransaccion: this.#getIdTransaction(),
      }
    }

    return this.#http.post<RevisionIneResponseInterface>(
      `${this.url}/${ServicesNames.REVISION_INE}`,
      body,
      { headers }
    )
    .pipe(
      map((response) => {
        if ( !response.process ) throw response.message;

        const parsedResponse: IneSuccesResponse | Ine404Response = JSON.parse(response.responseObject);

        if ( 'porcentajeFacial' in parsedResponse && parsedResponse.validacionDRL ) {
          return {
            process: true,
            value: 'Es usuario ha pasado'
          }
        }

        return {
          process: false,
          value: "Hubo un problema con los datos que ingresaste. Por favor, revisa la información y vuelve a intentarlo."
        }
      }),
      catchError((error) => manejarError(error))
    )
  }

  getReferencesSettings(): Promise<boolean> {
    return firstValueFrom(
      this.#globalVarServices.getSettings()
      .pipe(
        map((response) => {
          const index = response.findIndex(value => value.funcionalidad === ReferencesConfiguration.configuration);

          return response[index];
        }),
        map((response) => response.habilitado)
      )
    )
  }


  private mapCitiesResponse(data: ResponseList[]) {
    const setColonias = new Set();
    const setMunicipios = new Set();
    const setCiudades = new Set();
    const setStates = new Set();

    const colonias: DropdownObject[] = [];
    const municipios: DropdownObject[] = [];
    const ciudades: DropdownObject[] = [];
    const estados: DropdownObject[] = [];
    const region: string | undefined = data[0]?.region

    data.forEach((r: ResponseList) => {
      if (r.colonia !== '') setColonias.add(r.colonia.toUpperCase());
      if (r.municipio !== '') setMunicipios.add(r.municipio.toUpperCase());
      if (r.ciudad !== '') setCiudades.add(r.ciudad.toUpperCase());
      if (r.estado !== '') setStates.add(r.estado.toUpperCase());
    });

    setColonias.forEach((m: any) => colonias.push({ name: m, code: m }));
    setMunicipios.forEach((m: any) => municipios.push({ name: m, code: m }));
    setCiudades.forEach((m: any) => ciudades.push({ name: m, code: m }));
    setStates.forEach((m: any) => estados.push({ name: m, code: m }));

    return {
      colonias,
      municipios,
      ciudades,
      estados,
      region
    }
  }
}
