import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { catchError, throwError } from 'rxjs';

import { ServicesNames } from '@core/enums';
import { superUrl } from '@core/helpers';
import { ValidURLResponse } from '@core/interfaces';
import { environment } from '@envs/environment';

interface ParamsUrl {
  idAgente: string|null;
}


@Injectable({
  providedIn: 'root'
})
export class ValidUrlService {
  private readonly http = inject(HttpClient);

  get url () {
    return superUrl;
  }


  validUrl(data: ParamsUrl) {
    const headers = new HttpHeaders({
      'x-api-key': environment.apiKey_ValidUrl
    });

    const req = `${this.url}/${ServicesNames.VALIDAR_DATOS_URL}`
    return this.http.post<ValidURLResponse>(req, data, { headers })
    .pipe(
      catchError( error => {
        const statusCode = error.status;
        switch (statusCode) {
          case 401:
            return throwError(() => 'Acceso no autorizado');
          case 404:
            return throwError(() => error.error);
          case 500:
            return throwError(() => 'Error de servidor');
          default:
            break;
        }
        return throwError(() => error.statusText)
      })
    );
  }
}
