import { TimeLineLabels } from "../enums";
import { TimeLineItem } from "../interfaces";


export const timeLineCatFlow = (): TimeLineItem[] => {
  return [
    { label: TimeLineLabels.NUMERO_TELEFONICO},
    { label: TimeLineLabels.DATOS_PERSONALES },
    { label: TimeLineLabels.SMARTPHONES },
    { label: TimeLineLabels.ENTREGA },
    { label: TimeLineLabels.FORMA_PAGO },
    { label: TimeLineLabels.FINALIZACION }
  ]
}
