export function getMonthNameInSpanish(monthNumber: number): string {
  const monthsInSpanish = [
    'enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio',
    'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'
  ];

  if (monthNumber < 1 || monthNumber > 12) {
    throw new Error('El número del mes debe estar entre 1 y 12');
  }

  return monthsInSpanish[monthNumber - 1];
}
