import { inject, Injectable, signal } from '@angular/core';

import { DeliverySchedule } from '../interfaces';
import { Store } from '@ngrx/store';
import { saveDeliverySchedule } from '@store/index';

@Injectable({
  providedIn: 'root'
})
export class AddressSharedService {
  readonly #store = inject(Store);

  #deliverySchedule = signal<DeliverySchedule>(
    {
      deliverySchedule: '',
      deliveryScheduleId: '',
      date: ''
    }
  );
  #clickingBackButton = signal(false);
  #navigateToPayment = signal(false);
  #errorGeneratingSMTAppointment = signal<string | null>(null);

  get clickingBackButton() {
    return this.#clickingBackButton.asReadonly();
  }

  get deliverySchedule() {
    return this.#deliverySchedule.asReadonly();
  }

  get errorGeneratingSMTAppointment() {
    return this.#errorGeneratingSMTAppointment.asReadonly();
  }

  get navigateToPayment() {
    return this.#navigateToPayment.asReadonly();
  }


  onClickingBackButton(value: boolean) {
    this.#clickingBackButton.set(value);
  }

  onSetDeliverySchedule(properties: DeliverySchedule) {
    this.#deliverySchedule.set(properties);
    this.#store.dispatch(saveDeliverySchedule({ value: this.#deliverySchedule() }));
  }

  onNavigateToPayment(value: boolean) {
    this.#navigateToPayment.set(value);
  }

  onSetErrorSMTAppointment(error: string | null) {
    this.#errorGeneratingSMTAppointment.set(error);
  }
}
