import { createSelector } from "@ngrx/store";

import { PagosPlazos } from "@equipos/interfaces";
import { AppState } from "@store/interfaces";
import { PaymentAttributesInterface } from '../../interfaces';


export const selectPaymentAttributesFeature = (state: AppState) => state.payment;

export const selectTipoPago = createSelector(
  selectPaymentAttributesFeature,
  (state: PaymentAttributesInterface) => state.tipoPago
);

export const selectPeriodicidad = createSelector(
  selectPaymentAttributesFeature,
  (state: PaymentAttributesInterface) => state.periodicidad
);

export const selectPagosPlazos = createSelector(
  selectPaymentAttributesFeature,
  (state: PaymentAttributesInterface): PagosPlazos => (
    {
      nro_cuotas: state.nro_cuotas,
      periodicidad: state.periodicidad,
      aportacion_voluntaria: state.aportacion_voluntaria,
      sarasa: state.sarasa,
      enganche: state.enganche,
      factor: state.factor,
      producto: state.producto,
      cuotas: state.cuotas
    })
);

export const selectPutSolicitud = createSelector(
  selectPaymentAttributesFeature,
  (state: PaymentAttributesInterface) => state.putSolicitud
);

export const selectNumeroCuotas = createSelector(
  selectPaymentAttributesFeature,
  (state: PaymentAttributesInterface) => state.nro_cuotas,
);

export const selectTagGetProperties = createSelector(
  selectPaymentAttributesFeature,
  (state: PaymentAttributesInterface) => state.tagGetProperties,
);

export const selectCuotas = createSelector(
  selectPaymentAttributesFeature,
  (state: PaymentAttributesInterface) => state.cuotas,
);

export const selectLastCardNumbers = createSelector(
  selectPaymentAttributesFeature,
  (state: PaymentAttributesInterface) => state.lastCardNumbers,
);

export const selectCardType = createSelector(
  selectPaymentAttributesFeature,
  (state: PaymentAttributesInterface) => state.cardType,
);
