import { computed, inject, Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import moment from "moment";
import { catchError, concatMap, EMPTY, map, mergeMap, tap } from 'rxjs';

import { GoogleTagManagerService } from "@core/services";
import { AddressSharedService, DireccionEntregaService } from "@entrega-equipo/services";
import { selectPhone, selectPromotionCode } from "../phone";
import { selectAddressSMT, selectDatosEnvio, selectIdHorario, selectSucursal, selectTipoEntregaSeleccionada } from "./address.selectors";


@Injectable()
export class AddressAttributesEffects {
  readonly #actions$ = inject(Actions);
  readonly #store = inject(Store);
  readonly #direccionEntregaService = inject(DireccionEntregaService);
  readonly #googleTagManager = inject(GoogleTagManagerService);
  readonly #addressSharedService = inject(AddressSharedService);

  readonly #selectIdHorario = this.#store.selectSignal(selectIdHorario);
  readonly #selectBranchOffice = this.#store.selectSignal(selectSucursal);
  readonly #phone = this.#store.selectSignal(selectPhone);
  readonly #promotionCode = this.#store.selectSignal(selectPromotionCode);
  readonly #tipoEntregaSeleccionada = this.#store.selectSignal(selectTipoEntregaSeleccionada);
  readonly #datosEnvio = this.#store.selectSignal(selectDatosEnvio);
  readonly #address = this.#store.selectSignal(selectAddressSMT);

  readonly #deliverySchedule = computed(() => this.#addressSharedService.deliverySchedule());

  get selectedBranchOffice() {
    return this.#selectBranchOffice()!;
  }

  get deliverySchedule() {
    return this.#deliverySchedule();
  }

  get phone() {
    return this.#phone()!;
  }

  get datosEnvio() {
    return this.#datosEnvio()!;
  }

  get promotionCode() {
    return this.#promotionCode();
  }

  get shippingTieLabel() {
    if (this.#tipoEntregaSeleccionada() === 'tienda') {
      return 'Entrega en sucursal';
    }

    return 'Entrega a domicilio';
  }

  get address() {
    return this.#address()!;
  }


  generateSMTAppointment$ = createEffect(() => {
    return this.#actions$.pipe(
      ofType('[Address]: Generating SMT Appointment'),
      mergeMap(() => {
        return this.#direccionEntregaService.reservaCita(this.#selectIdHorario()!)
          .pipe(
            concatMap(() => this.generarCitaEntre(this.deliverySchedule.deliverySchedule)
              .pipe(
                map(() => {
                  this.#addressSharedService.onNavigateToPayment(true);
                  this.sendEvent();
                  return {
                    type: '[Address]: SMT Appointment Successfully Generated',
                  }
                })
              ))
          )
      })
    )
  });


  private generarCitaEntre(horario: string) {
    return this.#direccionEntregaService.generarCitaEntrega(this.generarCitaEntregaRequest())
      .pipe(
        tap(() => this.callRegistrarEnvioDomicilio(horario)),
        catchError((error: Error) => {
          console.log('error al generar la cita');
          console.log(error);

          this.#addressSharedService.onSetErrorSMTAppointment(error.message);
          this.cancelaDispo();
          return EMPTY;
        })
      );
  }

  private callRegistrarEnvioDomicilio(horario: string) {
    const formatDate = moment(this.deliverySchedule.date).format('YYYYMMDD');
    const tipoEntrega = this.#tipoEntregaSeleccionada() === 'tienda' ? 'CAT' : 'SMT';

    this.#direccionEntregaService
      .registrarEnvioDomicilio(moment(formatDate).format('DD/MM/YYYY'), horario, tipoEntrega).subscribe();
  }

  private cancelaDispo() {
    this.#direccionEntregaService.cancelaCita().subscribe();
  }

  private generarCitaEntregaRequest() {
    return {
      calle: this.datosEnvio.calle,
      numeroExterior: this.datosEnvio.numeroExterior,
      numeroInterior: this.datosEnvio.numeroInterior,
      referencia: this.datosEnvio.referencia,
      cp: this.datosEnvio.cp,
      estado: this.datosEnvio.estado,
      colonia: this.datosEnvio.colonia,
      municipio: this.datosEnvio.municipio
    }
  }

  private sendEvent() {
    const getAddress = this.generarCitaEntregaRequest();
    this.#googleTagManager.sendEvent({
      'event': 'add_shipping_info',
      'currency': 'MXN',
      'coupon': this.promotionCode?.code || 'N/A',
      'value': this.promotionCode?.value || 'N/A',
      'shipping_tie': this.shippingTieLabel,
      'items': [
        {
          'item_id': this.phone.codigo,
          'item_name': this.phone.nombre_comercial,
          'discount': this.promotionCode?.value || 'N/A',
          'index': 0,
          'item_brand': this.phone.marca,
          'item_category': 'Celulares',
          'item_variant': this.phone.field_color,
          'location_id': this.selectedBranchOffice?.nombre || 'N/A',
          'direccion': this.address,
          'estado': getAddress.estado,
          'price': this.phone.precio_iva,
          'quantity': 1
        }
      ]
    });
  }
}
