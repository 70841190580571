import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';

@Component({
  selector: 'app-time-line-item',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgClass
  ],
  templateUrl: './time-line-item.component.html',
  styleUrl: './time-line-item.component.scss'
})
export class TimeLineItemComponent {
  label = input('');
  classes = input([], { transform: this.#trimString });


  #trimString(value: string[] | undefined) {
    if (!value) {
      return ['']
    }

    return value?.map((value) => value.trim())
  }
}
