import { inject, Injectable, computed } from '@angular/core';
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { catchError, concat, concatMap, EMPTY, map, mergeMap, of, switchMap, throwError } from "rxjs";

import { ClienteService } from "@cliente/services";
import { tipoMediosOTP } from "@core/interfaces";
import { AppStoreService, GoogleTagManagerService } from "@core/services";
import { SolicitaCuentaService } from "@email/email-confirmar/services";
import { EmailService } from "@email/services";
import { PagoService } from "@pago/services";
import { RegistroService } from "@registro/services";
import { selectIdFlujo } from "../global";
import { selectEmail, selectClientPhone } from "./client.selectors";


@Injectable()
export class ClientAttributesEffects {
  readonly #actions = inject(Actions);
  readonly #store = inject(Store);
  readonly #registroService = inject(RegistroService);
  readonly #appStoreService = inject(AppStoreService);
  readonly #emailService = inject(EmailService);
  readonly #clienteService = inject(ClienteService);
  readonly #googleTagManager = inject(GoogleTagManagerService);
  readonly #solicitaCuentaService = inject(SolicitaCuentaService);
  readonly #pagoService = inject(PagoService);

  readonly #selectClientPhone = this.#store.selectSignal(selectClientPhone);
  readonly #selectIdFlujo = this.#store.selectSignal(selectIdFlujo);
  readonly #selectClientEmail = this.#store.selectSignal(selectEmail);
  readonly #getEmailOTP = computed(() => this.#appStoreService.emailOTP());


  loadTokenAndStartFlow$ = createEffect(() => {
    return this.#actions.pipe(
      ofType('[Client]: Client Phone Successfully Saved'),
      mergeMap(() => {
        return this.#registroService.registerClient(
          {
            numeroCelular: this.#selectClientPhone()!,
            medioOTP: tipoMediosOTP.SMS,
            flujoInicial: String(this.#selectIdFlujo())!
          }
        ).pipe(
          map(({ email, idTransaccion, jwt }) => ({
            type: '[Client]: Email, Token And TransactionID Successfully Saved',
            email,
            idTransaction: idTransaccion,
            token: jwt
          }))
        )
      })
    )
  });

  loadIfClientContainsPhoneSaved$ = createEffect(() => {
    return this.#actions.pipe(
      ofType('[Global]: Saved URL Success'),
      map(() => {
        const { containsSavedPhone } = this.#appStoreService.clientData;
        return {
          type: '[Client]: existTelefono Successfully Saved',
          value: containsSavedPhone
        }
      })
    )
  });

  registerEmailAndloadInfoClient$ = createEffect(() => {
    return this.#actions.pipe(
      ofType('[Client]: Email Successfully Saved'),
      mergeMap(() => {
        return this.#emailService.registerEmail(this.#selectClientEmail()!)
          .pipe(
            switchMap(() => this.#clienteService.getInfoCliente()
              .pipe(
                map((clientInfo) => {
                  this.#googleTagManager.sendEvent({
                    'event': 'registration_steps',
                    'registration_section': 'CorreoElectronico'
                  });
                  return {
                    type: '[Client]: Client Information Successfully Saved',
                    value: clientInfo
                  }
                })
              )),
            catchError((error) => {
              this.#emailService.onExistErrorToNavigateRoute({ show: true, message: error });
              return throwError(() => error);
            }
            )
          )
      })
    )
  });

  callServicesToValidateOTPEmail$ = createEffect(() => {
    return this.#actions.pipe(
      ofType('[Client]: Validating Email OTP'),
      mergeMap(() => this.#emailService.emailOtpValidation(this.#getEmailOTP()).pipe(
        concatMap(() => this.validateSolicitudCuenta()),
        catchError((error) => {
          this.handleValidationError(error);
          return EMPTY;
        })
      ))
    );
  });


  private validateSolicitudCuenta() {
    return this.#solicitaCuentaService.getSolicitud().pipe(
      concatMap((getSolicitudResponse) => {
        const isNumber = typeof getSolicitudResponse === 'number';

        return isNumber
          ? this.#solicitaCuentaService.solicitarCuenta().pipe(
            concatMap(() => this.callEmailEdgeEvaluatorAndSeverityLevel())
          )
          : this.callEmailEdgeEvaluatorAndSeverityLevel();
      })
    );
  }

  private callEmailEdgeEvaluatorAndSeverityLevel() {
    return this.#solicitaCuentaService.emailEdge().pipe(
      concatMap(() => this.handleEvaluatorAndSeverityLevel()),
      catchError(() => this.callCatchErrorEmailEdgeEvaluatorAndSeverityLevel())
    );
  }

  private handleEvaluatorAndSeverityLevel() {
    return this.#pagoService.evaluator().pipe(
      concatMap((evaluatorResponse) => {
        // Llamar el action después del evaluador y continuar con severityLevel
        const evaluatorAction = {
          type: '[Client]: ICC, BCC and scoreEvaluador Saved',
          ...evaluatorResponse
        };

        return concat(
          of(evaluatorAction), // Despachar el action
          this.#pagoService.severityLevel(evaluatorResponse).pipe(
            map((severityLevelResponse) => {
              this.completeValidationFlow();
              return {
                type: '[Client]: Severity Level Successfully Saved',
                value: severityLevelResponse
              };
            }),
            catchError(() => this.callCatchErrorEmailEdgeEvaluatorAndSeverityLevel())
          )
        );
      })
    );
  }

  private callCatchErrorEmailEdgeEvaluatorAndSeverityLevel() {
    this.completeValidationFlow();
    return of({
      type: '[Client]: Severity Level Successfully Saved',
      value: 0
    });
  }

  private completeValidationFlow() {
    this.sendCodigoSeguridadEvent();
    this.#emailService.onNavigateRoute(true);
  }

  private handleValidationError(error: any) {
    this.#emailService.onExistErrorToNavigateRoute({ show: true, message: error });
  }

  private sendCodigoSeguridadEvent() {
    this.#googleTagManager.sendEvent({
      event: 'registration_steps',
      registration_section: 'CodigoSeguridadEmail'
    });
  }
}
