import { createAction, props } from "@ngrx/store";
import { GlobalAttributesInterface } from "../../interfaces";


export const loadGlobalAttributes = createAction(
  '[Global]: Load Global Attributes'
);

export const initGlobalStorage = createAction(
  '[Global]: Init Global State Success',
)


export const loadedGlobalAttributes = createAction(
  '[Global]: Loaded Global Attributes Success',
  props<{ payload: GlobalAttributesInterface }>()
);


export const enableComputerUse = createAction(
  '[Global]: EnableComputerUseSuccess',
  props<{ computerUse: boolean }>()
);

export const saveInitialURL = createAction(
  '[Global]: Initial URL Successfully Saved',
  props<{ url: string }>()
)

export const setIdEmpleado = createAction(
  '[Global]: Set ID Empleado',
  props<{ id: string }>()
)

export const setURLSaved = createAction(
  '[Global]: Saved URL Success',
  props<{ url: string }>()
)

export const saveExpressIDToken = createAction(
  '[Global]: Saved ExpressID Token Success',
  props<{ value: string }>()
)

export const saveValidationID = createAction(
  '[Global]: Saved ValidationID Success',
  props<{ value: string }>()
)

export const saveClaveFuerzaVenta = createAction(
  '[Global]: Saved Clave Fuerza Venta Success',
  props<{ value: string }>()
)
