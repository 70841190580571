import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { catchError, map } from 'rxjs/operators';

import { ServicesNames } from '@core/enums';
import { manejarError, removeAccents, superUrl } from '@core/helpers';
import { environment } from '@envs/environment';
import { Phone } from '@equipos/interfaces';
import { Store } from '@ngrx/store';
import { saveAddress, saveClaveFuerzaVenta, saveFolioEnvio, saveFolioReserva, selectClaveFuerzaVenta, selectClaveOficinaAlmacen, selectClientAttributesFeatures, selectExternalID, selectFuerzaVenta, selectOficinaDescripcion, selectOficinaID, selectPaymentAttributesFeature, selectPhone, selectRegion, selectSeriePhone } from '@store/index';
import { selectDatosEnvio, selectFolioEnvio, selectFolioReserva, selectIdHorario, selectNombrePaqueteria, selectSucursal } from '@store/modules/address/address.selectors';
import { CancelaDisp, ConsultAppointmentsResponse, DatosEnvio, GetEnvioDomicilioResponse, RegistrarEnvioDomicilio, ReservaDisp } from '../interfaces';
@Injectable({
  providedIn: 'root'
})
export class DireccionEntregaService {
  readonly #http = inject(HttpClient);
  readonly #store = inject(Store);

  readonly #clientData = this.#store.selectSignal(selectClientAttributesFeatures);
  readonly #payment = this.#store.selectSignal(selectPaymentAttributesFeature);
  readonly #region = this.#store.selectSignal(selectRegion);
  readonly #officeID = this.#store.selectSignal(selectOficinaID);
  readonly #fuerzaVenta = this.#store.selectSignal(selectFuerzaVenta);
  readonly #claveOficinaAlmacen = this.#store.selectSignal(selectClaveOficinaAlmacen);
  readonly #claveFuerzaVenta = this.#store.selectSignal(selectClaveFuerzaVenta);
  readonly #folioReserva = this.#store.selectSignal(selectFolioReserva);
  readonly #idHorario = this.#store.selectSignal(selectIdHorario);
  readonly #externalID = this.#store.selectSignal(selectExternalID);
  readonly #oficinaDescripcion = this.#store.selectSignal(selectOficinaDescripcion);
  readonly #sucursal = this.#store.selectSignal(selectSucursal);
  readonly #nombrePaqueteria = this.#store.selectSignal(selectNombrePaqueteria);
  readonly #folioEnvio = this.#store.selectSignal(selectFolioEnvio);
  readonly #datosEnvio = this.#store.selectSignal(selectDatosEnvio);

  readonly #selectedPhone = this.#store.selectSignal(selectPhone);
  readonly #serie = this.#store.selectSignal(selectSeriePhone);

  get url() {
    return superUrl;
  }


  /* Estos 3 servicios hacen referencia a la disponibilidad para apartar un cupo de paqueteria , aun no se confirma la cita*/
  consultaCitas({ date, colonia, codigoPostal }: { date: string; colonia: string; codigoPostal: string }) {
    const headers = new HttpHeaders({
      'x-api-key': environment.apiKey_consultaCitas
    });

    const body = {
      payload: {
        envioDomicilio: !this.#sucursal() ? true : false,
        idCac: this.#sucursal()?.idCac,
        region: this.#region(),
        almacen: this.#officeID(),
        codigoPostal,
        descripcionCac: this.#oficinaDescripcion() || 'PLAZA CARSO',
        colonia,
        fuerzaVentas: this.#fuerzaVenta(),
        claveFzaVenta: this.#claveOficinaAlmacen(),
        diaEntrega: date //20230620
      }
    }
    return this.#http.post<ConsultAppointmentsResponse>(
      `${this.url}/${ServicesNames.CONSULTA_CITA_ENVIO_DISPONIBLE}`,
      body,
      { headers }
    ).pipe(
      map((response) => {
        if (!response.process) throw response.message;
        if (!response.responseObject.mensajeria.length) throw 'No existen horarios disponibles, intente con una fecha distinta.';

        const { claveFzaVenta, mensajeria } = response.responseObject;
        this.#store.dispatch(saveClaveFuerzaVenta({ value: claveFzaVenta }));

        return mensajeria;
      }),
      map((mensajerias) => {
        /**
         * Retornamos un array de objetos con la siguiente estructura:
         * [[{ label: string; value: string; }]]
         *
         * El flat() nos ayuda a aplanar el resultado del arreglo para dejarlo de esta manera:
         *  [[{ label: string; value: string; }]] ->  [{ label: string; value: string; }]
         */
        const getDisponibilidadWithNames = mensajerias.map((mensajeria) => {
          /** Recuperamos las disponibilidades de la mensajeria a iterar */
          const getDisponibilidades = mensajeria.disponibilidad;

          /**
           * Retornamos el nuevo array de objetos agregandole la horaEntrea - nombre de la mensajeria
           * y lo ordenamos por disponibilidad con el método sort
           */
          return getDisponibilidades.flat()
            .map(d => ({ label: `${d.horaEntrega} - ${mensajeria.nombre}`, value: d.idDisp }))
            .sort((disponibilidad1, disponibilidad2) => {
              const numA = parseInt(disponibilidad1.label.split('-')[0]);
              const numB = parseInt(disponibilidad2.label.split('-')[0]);
              return numA - numB;
            });
        }).flat();

        /** Obtenemos las disponibilidad sin el nombre */
        const getDisponibilidadWithoutNames = mensajerias.map((mensajeria) => {
          /** Recuperamos las disponibilidades de la mensajeria a iterar */
          const getDisponibilidades = mensajeria.disponibilidad;

          /**
           * Retornamos el nuevo array de objetos agregandole la horaEntrea - nombre de la mensajeria
           * y lo ordenamos por disponibilidad con el método sort
           */
          return getDisponibilidades.flat()
            .map(d => ({ label: `${d.horaEntrega}`, value: d.idDisp }))
            .sort((disponibilidad1, disponibilidad2) => {
              const numA = parseInt(disponibilidad1.label.split('-')[0]);
              const numB = parseInt(disponibilidad2.label.split('-')[0]);
              return numA - numB;
            });
        }).flat();

        return {
          getDisponibilidadWithNames,
          getDisponibilidadWithoutNames
        }
      }),
      catchError((error) => manejarError(error))
    );
  }

  reservaCita(idDisp: string) {
    const headers = new HttpHeaders({
      'x-api-key': environment.apiKey_reservaCitas
    });

    const body = {
      payload: {
        region: this.#region(),
        fuerzaVentas: this.#fuerzaVenta(),//"PLAZA CARSO",
        claveFzaVenta: this.#claveFuerzaVenta(),//"M697-C090",
        idDisp: idDisp
      }
    }

    return this.#http.post<ReservaDisp>(
      `${this.url}/${ServicesNames.FOLIO_RESERVA_ENVIO}`,
      body,
      { headers }
    ).pipe(
      map((response) => {
        if (!response.process) throw response.message;

        const folioReserva = response.responseObject.folioReserva;
        this.#store.dispatch(saveFolioReserva({ value: folioReserva }));

        return response.identifier;
      }),
      catchError((error) => manejarError(error))
    );
  }

  cancelaCita() {
    const headers = new HttpHeaders({
      'x-api-key': environment.apiKey_cancelaCitas
    });

    const body = {
      payload: {
        region: this.#region(),
        fuerzaVentas: this.#fuerzaVenta(), //"CARSO",
        claveFzaVenta: this.#claveFuerzaVenta(), //"M697-C090",
        idDisp: this.#idHorario(),
        folioReserva: this.#folioReserva() //"R091363702"
      }

    }
    return this.#http.post<CancelaDisp>(
      `${this.url}/${ServicesNames.CANCELAR_RESERVA_ENVIO}`,
      body,
      { headers }
    ).pipe(
      map((response) => {
        if (!response.process) throw response.message;

        return response.responseObject;
      }),
      catchError((error) => manejarError(error))
    );
  }
  /* Estos 3 servicios hacen referencia a la cita y crear una orden entrega*/
  generarCitaEntrega(datosaddres: DatosEnvio) {
    const fuerzaVentas = this.#fuerzaVenta()!;
    const codigoAlmacen = this.#claveFuerzaVenta()!;
    const folioReserva = this.#folioReserva()!;
    const numeroCuenta = this.#externalID()!
    const sexo = this.#clientData().sexo?.includes("FEMENINO") ? 2 : 1;
    const numeroTelefonico = this.#clientData()!.telefono;
    const claveFzaVenta = this.#claveOficinaAlmacen()! // M901-C704
    const region = this.#region()!;

    const plazosNumber = this.#payment().nro_cuotas;

    const cargoFijo = this.#payment().cuotas[0].monto
    const email = this.#clientData().correoElectronico
    const datosTelefono: Phone = this.#selectedPhone()!;
    const headers = new HttpHeaders({
      'x-api-key': environment.apiKey_generarCitaEntrega
    });

    let estado = datosaddres.estado;
    let ciudad = datosaddres.colonia;
    let municipio = datosaddres.municipio;
    let colonia = datosaddres.colonia;

    const body = {
      payload: {
        region: region,
        fuerzaVentas,//"PLAZA CARSO"
        claveFzaVenta,//CARSO
        pzoForzoso: plazosNumber.toString(),
        nombrePlan: "Amigo paguitos",
        cargoFijo: cargoFijo,
        numeroServicio: numeroTelefonico,//5510332977"//numeroTelefonico,
        folioReserva,
        orderNo: numeroCuenta,
        enganche: this.#payment().enganche.toFixed(2).toString(),
        meses: plazosNumber.toString(),
        folioIden: this.#clientData().folioIne!,
        rfc: this.#clientData().rfc,
        fechaNac: this.#clientData().fechaNacimiento,//MM/DD/YYYY
        identificacion: "IFE/INE",
        folioEcac: numeroCuenta,
        almacen: codigoAlmacen,
        cveSAP: datosTelefono.codigo,//datosTelefono.codigo_de_material,
        skuName: datosTelefono.nombre_comercial,
        noSerial: this.#serie()!,//imei,
        nombre: this.#clientData().nombre,
        apPaterno: this.#clientData().apellidoPaterno,
        apMaterno: this.#clientData().apellidoMaterno,
        calle: datosaddres.calle,
        numeroExt: datosaddres.numeroExterior,
        numeroInt: datosaddres.numeroInterior,
        estado: removeAccents(estado),
        ciudad: removeAccents(ciudad),
        municipio: removeAccents(municipio),
        referencia: datosaddres.referencia,
        comentario: `Se factura un teléfono ${datosTelefono.marca}`,
        colonia: removeAccents(colonia),
        codigoPostal: datosaddres.cp,
        correoElectronico: email,
        telefono: numeroTelefonico,
        sexo,
        telefonoContacto1: numeroTelefonico,
        nombreContacto1: this.#clientData().nombre,
        idcac: this.#sucursal()?.idCac,
      }
    }


    return this.#http.post<ReservaDisp>(
      `${this.url}/${ServicesNames.GENERAR_CITA_ENTREGA_ENVIO}`,
      body,
      { headers }
    ).pipe(
      map((response) => {
        if (!response.process) throw response.message;

        const { calle, numeroExt, numeroInt, colonia } = body.payload;
        let address = `${calle} No.${numeroExt} NoI.${numeroInt} ${colonia}`;

        if (!numeroInt) {
          address = `${calle} No.${numeroExt} ${colonia}`;
        }

        this.#store.dispatch(saveAddress({ value: address }));
        this.#store.dispatch(saveFolioEnvio({ value: response.responseObject.folioCita }));
        return response.responseObject.folioCita;
      }),
      catchError((error) => manejarError(error))
    );
  }

  cancelaPaqueteria() {
    const headers = new HttpHeaders({
      'x-api-key': environment.apiKey_cancelaCitaEntrega
    });

    const body = {
      payload: {
        region: this.#region(),
        fuerzaVentas: this.#fuerzaVenta(),
        claveFzaVenta: this.#claveFuerzaVenta(),
        folioCita: this.#folioEnvio()
      }
    }

    return this.#http.post<ReservaDisp>(
      `${this.url}/${ServicesNames.CANCELAR_CITA_ENTREGA_ENVIO}`,
      body,
      { headers }
    ).pipe(
      map((response) => {
        if (!response.process) throw response.message;
        return response.identifier;
      }),
      catchError((error) => manejarError(error))
    );
  }

  detallePaqueteria() {
    const headers = new HttpHeaders({
      'x-api-key': environment.apiKey_reservaCitas
    });

    const body = {
      payload: {
        region: this.#region(),
        fuerzaVentas: this.#fuerzaVenta(),//"CARSO",
        claveFzaVenta: this.#claveFuerzaVenta(),//"M697-C090",
        folioCita: this.#folioEnvio()
      }
    }

    return this.#http.post<ReservaDisp>(
      `${this.url}/${ServicesNames.DETALLE_CITA_ENVIO}`,
      body,
      { headers }
    ).pipe(
      map((response) => {
        if (!response.process) throw response.message;
        return response.responseObject;
      }),
      catchError((error) => manejarError(error))
    );
  }

  registrarEnvioDomicilio(fecha: string, horario: string, tipoEntrega: 'CAT' | 'SMT' = 'SMT') {
    const headers = new HttpHeaders({
      'x-api-key': environment.apiKey_registrarEnvio
    });

    const body = {
      payload: {
        codigoPostal: this.#datosEnvio()?.cp,
        calle: this.#datosEnvio()?.calle,
        numExterior: this.#datosEnvio()?.numeroExterior,
        colonia: this.#datosEnvio()?.colonia,
        "municipio_alcaldia": this.#datosEnvio()?.municipio,
        estado: this.#datosEnvio()?.estado,
        referencia: this.#datosEnvio()?.referencia,
        fecha,
        hora: horario,
        tipoEntrega,
        mensajeria: this.#sucursal()?.idCac ? null : this.#nombrePaqueteria()
      }
    }

    return this.#http.post<RegistrarEnvioDomicilio>(
      `${this.url}/${ServicesNames.REGISTRAR_ENVIO_DOMICILIO}`,
      body,
      { headers }
    ).pipe(
      map((response) => {
        if (!response.process) throw 'Hay un error al momento de registrar el domicilio';
        return response;
      })
    );
  }

  getEnvioDomicilio() {
    const headers = new HttpHeaders({
      'x-api-key': environment.apiKey_registrarEnvio
    });

    const body = {
      payload: null
    }

    return this.#http.post<GetEnvioDomicilioResponse>(
      `${this.url}/${ServicesNames.GET_ENVIO_DOMICILIO}`,
      body,
      { headers }
    ).pipe(
      map((response) => {
        if (!response.process) throw 'Hubo un error al recuperar la dirección';
        return response.responseObject;
      })
    );
  }
}
