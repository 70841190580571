import { Condicionesp, PagosPlazos, Phone } from '@equipos/interfaces';
import { CreditCardInformation } from '../interfaces';


export function generateConfirmacionPagoBody(cardInformation: CreditCardInformation, myIp: string) {
  return {
    confirmacionPago: {
      body: generatePayPhoneBody(cardInformation, myIp)
    }
  }
}

export function generateConsultaRecursoBody() {
  return {
    consultaRecurso: {
      body: generateConsultResourceBody()
    }
  }
}

export function generateCotizacionBody() {
  const getPaymentTerms: PagosPlazos = JSON.parse(sessionStorage.getItem('pagosPlazos') || '[]');
  return {
    cotizacion: {
      body: {
        payload: {
          ...getPaymentTerms,
          periodicidad: getPaymentTerms.periodicidad.toLowerCase()
        }
      }
    }
  }
}

export function generateGenerarNotaBody() {
  return {
    generarNota: {
      body: generateNoteRequest()
    }
  }
}

export function generatePutSolicitud() {
  // let numeroAleatorio = '';
  // for (let i = 0; i < 15; i++) {
  //   const digito = Math.floor(Math.random() * 10); // Números del 0 al 9
  //   numeroAleatorio += digito;
  // }

  // sessionStorage.setItem('serieRandom', numeroAleatorio);

  return {
    putSolicitud: {
      body: {
        payload: {
          pasoPago: true,
          imei: sessionStorage.getItem('serie'),
          // imei: sessionStorage.getItem('serieRandom'),
          numCuenta: sessionStorage.getItem('externalId'),
          sessionId: sessionStorage.getItem('sessionId')!,
          sessionKey: sessionStorage.getItem('sessionKey')!,
          orgId: sessionStorage.getItem('orgId')!,
          factura: sessionStorage.getItem('factura'),
          updateTelefono: false,
          tipoPago: sessionStorage.getItem('tipoPago')
        }
      }
    }
  }
}

export function generatePostPrestamoBody() {
  return {
    postPrestamo: {
      body: {
        payload: {
          numCuenta: sessionStorage.getItem('externalId')
        }
      }
    },
  }
}

export function generateObtenerContratoBody() {
  return {
    obtenercontrato: {
      body: obtenerContratoRequest()
    }
  }
}

export function generateGenerarContratoBody() {
  return {
    generarContrato: {
      body: generateContractRequest()
    }
  }
}

export function generateFirmaContratoBody() {
  return {
    firmaContrato: {
      body: {
        payload: {
          cuenta: sessionStorage.getItem('externalId'),
          signatureId: '',
          signatureRequestId: ''
        }
      }
    }
  }
}

export function generateSaveContract() {
  return {
    saveContract: {
      body: {
        payload: {
          cuenta: sessionStorage.getItem('externalId'),
          signatureId: '',
          signatureRequestId: ''
        }
      }
    }
  }
}

export function generateEnviarCredecialesBody() {
  return {
    enviarCredeciales: {
      body: enviarCredencialesRequest()
    }
  }
}

export function generateDetalleCitaEnvioBody() {
  return {
    detalleCitaEnvio: {
      body: {
        payload: {
          region: '9',
          fuerzaVentas: sessionStorage.getItem('fuerzaVenta'),//"CARSO",
          claveFzaVenta: sessionStorage.getItem('claveOficinaAlmacen'),//"M697-C090",
          folioCita: sessionStorage.getItem('folioEnvio')
        }
      }
    }
  }
}

export function generateEnviaCorreoBody(isFlowSMT: boolean) {
  const direccion = JSON.parse(sessionStorage.getItem('datosEnvio') || '[]');
  const selectedPhone: Phone = JSON.parse(sessionStorage.getItem('phone')!);


  return {
    enviaCorreo: {
      body: {
        payload: {
          nombre: '',
          folioEntrega: '',
          fechaEntrega: '',
          horarioEntrega: '',
          paqueteria: '',
          calle: direccion.calle,
          numExterior: direccion.numeroExt,
          colonia: direccion.colonia,
          municipio: direccion.municipio,
          ciudad: direccion.estado,
          nombreEquipo: selectedPhone.nombre_comercial || '-',
          marca: selectedPhone.marca || '-',
          modelo: selectedPhone.modelo || '-',
          telefono: "telefono",
          correoElectronicoEnvio: sessionStorage.getItem("emailIngresado"),
          correoElectronicoContacto: sessionStorage.getItem("emailIngresado"),
          serie: sessionStorage.getItem('serie'),
          numCuenta: sessionStorage.getItem("externalId"), // Tratar de recuperarlos
          isFlowSMT

        }
      }
    }
  }
}

export function generateActualizarStatusClienteBody(estatus = 'Incompleto') {
  return {
    actualizaEstatusCliente: {
      body: {
        payload: {
          estatus,
          flujoFinal: sessionStorage.getItem('idFlujo')
        }
      }
    }
  }
}

export function generateReservaIMEIBody() {
  return {
    reservaImei: {
      body: {
        payload: {
          numCuenta: sessionStorage.getItem('externalId'),
          imei: sessionStorage.getItem('serie'),
        }
      }
    }
  }
}

export function generateRegistrarDispositivoBody() {
  return {
    registrarDispositivo: {
      body: {
        payload: {
          numCuenta: sessionStorage.getItem('externalId'),
          imei: null
        }
      }
    }
  }
}

export function notificarDescuento() {
  const dataDescuento = JSON.parse(sessionStorage.getItem('payloadCode')!);
  return {
    apiDescuentos: {
      body: {
        payload: {
          promocion: "TI",
          operacion: "TI2",
          voucher: dataDescuento.payload.voucher,
          region: 9,
          canal: sessionStorage.getItem('canal') || 'CAC',
          cuenta: sessionStorage.getItem('externalId'),
          idTransaccion: dataDescuento.payload.idTransaccion
        }
      }
    }
  }
}



function generatePayPhoneBody({ nombreTitular, numeroTarjeta, expiracion, cvv }: CreditCardInformation, myIp: string) {
  const selectedPhone: Phone = JSON.parse(sessionStorage.getItem('phone')!);
  const plazos = JSON.parse(sessionStorage.getItem('pagosPlazos') || '[]');
  const engancheConIva: number | string =
    (JSON.parse(sessionStorage.getItem('pagosPlazos')!) as PagosPlazos)?.enganche;

  return {
    payload: {
      nombre: nombreTitular,
      numTarjeta: numeroTarjeta.replaceAll(' ', '')!,
      cvv,
      fechaVencimiento: expiracion.replaceAll('/', '')!,
      region: sessionStorage.getItem('region')!,
      precio: engancheConIva,
      productoCode: selectedPhone.codigo,
      descripcion: selectedPhone!.descripcion,
      cantidad: 1,
      color: selectedPhone.field_color,
      capacidad: selectedPhone.field_capacidad_memoria_interna_,
      payShema: "PRE",
      planType: "Telcel Max Sin Límite",
      ipAdd: myIp,
      numPlazos: plazos.cuotas.length,
      numCuenta: sessionStorage.getItem('externalId')!,
      orgId: sessionStorage.getItem('orgId')!,
      sessionId: sessionStorage.getItem('sessionId')!,
      sessionKey: sessionStorage.getItem('sessionKey')!
    }
  }
}


function generateConsultResourceBody() {
  const clientData = JSON.parse(sessionStorage.getItem('datosCliente')!);
  const selectedPhone: Phone = JSON.parse(sessionStorage.getItem('phone')!);
  const idEmpleado = sessionStorage.getItem('idEmpleado');


  const agente = sessionStorage.getItem('agente');
  return {
    payload: {
      consultarRecurso: {
        agente: `${idEmpleado}|${agente}`, // INformación de telcel
        codigo: "0", // estatico
        region: sessionStorage.getItem('region'),
        recurso: [
          {
            sku: selectedPhone.codigo,
            codigoAlmacen: clientData?.cac?.oficina_id // es la oficina_id de la respuesta de los cacs
          }
        ]
      }
    }
  }
}

function generateNoteRequest() {
  const selectedPhone: Phone = JSON.parse(sessionStorage.getItem('phone')!);
  const precioConIva = (Number(selectedPhone?.precio_iva)).toFixed(2);
  const precioSinIva = (Number(precioConIva) / 1.16).toFixed(2);
  const conditionSelected: Condicionesp = JSON.parse(sessionStorage.getItem('conditionSelected')!);

  const clientData = JSON.parse(sessionStorage.getItem('datosCliente')!);

  const engancheConIva: number | string =
    (JSON.parse(sessionStorage.getItem('pagosPlazos')!) as PagosPlazos)?.enganche;

  const engancheSinIva = (Number(engancheConIva) / 1.16).toFixed(2);

  const idEmpleado = sessionStorage.getItem('idEmpleado');
  const agente = sessionStorage.getItem('agente');

  return {
    payload: {
      sku: selectedPhone.codigo,
      node_id: selectedPhone?.node_id, // Es el node_id del telefono seleccionado gracias al motorCalculo
      almacen: clientData?.cac?.oficina_id,
      observacion: conditionSelected?.clavePago,// Viene por el servicio del motorCalculo clavePago
      region: sessionStorage.getItem('region'),
      marca: selectedPhone.marca,
      modelo: selectedPhone.modelo,
      precio: Number(selectedPhone?.precio_iva).toFixed(2),
      precio_sin_iva: precioSinIva,
      sobreprecio: null, // Viene estatico
      pago_inicial: engancheSinIva, // Enganche sin IVA
      cantidad: "1",
      id_reservacion: sessionStorage.getItem('idReservacion'), // Es la respuesta del servicio de reservaREcurso reservarRecursoResponse.idReservacion
      serie: sessionStorage.getItem('serie'),
      agente: `${idEmpleado}|${agente}`, // INformación de telcel
      tipo_pago: "99",//por default
      codigo_impuesto: "S01",//por default
      cuenta: sessionStorage.getItem('externalId'), // Es el external_id de la respuesta al servicio de solictudCuenta
      numeroOrden: `AP-${sessionStorage.getItem('externalId')}-${Math.floor(Math.random() * 1001)}`,
      total_descuento: null,
      periodo_contrato: null,
      periodo_financiamento: null,
      descuento_con_iva: null,
      descuento_sin_iva: null,
      iva: "16",
      motivo: "M68",//por default
      tipo_activacion: null,
      tipo_descuento: null
    }
  }
}

function obtenerContratoRequest() {
  const getPaymentTerms: PagosPlazos = JSON.parse(sessionStorage.getItem('pagosPlazos') || '[]');
  const saldoPendiente = getPaymentTerms.cuotas
    .map(c => Number(c.monto))
    .reduce((current, previous) => current + previous)
    .toFixed(2);

  return {
    payload: {
      cuenta: sessionStorage.getItem('externalId'),
      linea: sessionStorage.getItem('numeroCelular'),
      pagoInicial: getPaymentTerms.enganche,
      saldoPendiente,
      cat: 'cat',
      parcialidades: getPaymentTerms.cuotas.length,
      periodicidad: getPaymentTerms.periodicidad,
      monto: getPaymentTerms.cuotas[0].monto,
      fechaCorte: getPaymentTerms.cuotas[getPaymentTerms.cuotas.length - 1].vencimiento,
      fechaPrimerPago: getPaymentTerms.cuotas[0].vencimiento,
      fzaVenta: sessionStorage.getItem('fuerzaVenta'),
      contrato: null,
      idTransaccion: sessionStorage.getItem('idTransaccion')
    }
  }
}

function generateContractRequest() {
  return {
    payload: {
      contrato: null,
      cuenta: sessionStorage.getItem('externalId'),
      idTransaccion: sessionStorage.getItem('idTransaccion')
    }
  }
}

function enviarCredencialesRequest() {
  return {
    payload: {
      numCuenta: sessionStorage.getItem('externalId'),
      idTransaccion: sessionStorage.getItem('idTransaccion')
    }
  }
}

