import { createReducer, on } from "@ngrx/store";

import { GlobalAttributesInterface } from '../../interfaces';
import { enableComputerUse, initGlobalStorage, loadedGlobalAttributes, saveClaveFuerzaVenta, saveExpressIDToken, saveInitialURL, saveValidationID, setIdEmpleado, setURLSaved } from "./global.actions";
import { INIT_GLOBAL_STATE } from "../../utilities";


export const globalInitialState: GlobalAttributesInterface = INIT_GLOBAL_STATE


export const GlobalAttributesReducer = createReducer(
  globalInitialState,
  on(
    loadedGlobalAttributes,
    (state, { payload }): GlobalAttributesInterface => ({...payload})
  ),

  on(
    initGlobalStorage,
    (): GlobalAttributesInterface => (INIT_GLOBAL_STATE)
  ),

  on(
    enableComputerUse,
    (state, { computerUse }): GlobalAttributesInterface => ({...state, useDesktop: computerUse})
  ),

  on(
    saveInitialURL,
    (state, { url }): GlobalAttributesInterface => ({...state, initialUrl: url})
  ),

  on(
    enableComputerUse,
    (state, { computerUse }): GlobalAttributesInterface => ({...state, useDesktop: computerUse})
  ),

  on(
    setIdEmpleado,
    (state, { id }): GlobalAttributesInterface => ({...state, idEmpleado: id})
  ),

  on(
    setURLSaved,
    (state, { url }): GlobalAttributesInterface => ({ ...state, preSavedUrl: url })
  ),

  on(
    saveExpressIDToken,
    (state, { value }): GlobalAttributesInterface => ({ ...state, tokenExpressID: value })
  ),

  on(
    saveValidationID,
    (state, { value }): GlobalAttributesInterface => ({ ...state, validationID: value })
  ),

  on(
    saveClaveFuerzaVenta,
    (state, { value }): GlobalAttributesInterface => ({ ...state, claveFuerzaVenta: value })
  )
)
