import { ChangeDetectionStrategy, Component, input } from '@angular/core';

import { TimeLineItem } from '@core/interfaces';
import { TimeLineItemComponent } from '../time-line-item/time-line-item.component';

@Component({
  selector: 'app-time-line',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    TimeLineItemComponent
  ],
  templateUrl: './time-line.component.html',
  styleUrl: './time-line.component.scss'
})
export class TimeLineComponent {
  timeLineItems = input.required<TimeLineItem[]>({ alias: 'items' })
}
