import { ChangeDetectionStrategy, ChangeDetectorRef, Component, computed, inject, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { interval, Subscription } from 'rxjs';

import { InactivityService } from '@core/services';
import { selectInitialURL } from '@store/index';


@Component({
  selector: 'app-inactivity-modal',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    DialogModule,
    ButtonModule
  ],
  templateUrl: './inactivity-modal.component.html',
  styleUrl: './inactivity-modal.component.scss'
})
export class InactivityModalComponent implements OnInit, OnDestroy {
  readonly #cdr = inject(ChangeDetectorRef);
  readonly #router = inject(Router);
  readonly #store = inject(Store);
  readonly #inactivityService = inject(InactivityService);

  readonly #initialURL = this.#store.selectSignal(selectInitialURL);

  #counter$ = interval(1000);
  #subscription: Subscription | undefined;

  timer = 30;
  readonly show = computed(() => this.#inactivityService.showModal());

  ngOnInit(): void {
    this.#subscription = this.#counter$.subscribe({
      next: () => {
        if (!this.timer) {
          this.onClose();
          this.#router.navigateByUrl(this.#initialURL()!);
          return;
        }
        this.timer--;
        this.#cdr.markForCheck();
      }
    })
  }

  ngOnDestroy(): void {
    this.#subscription?.unsubscribe();
  }


  onClose() {
    this.#inactivityService.onShow(false);
    this.#subscription?.unsubscribe();
    this.timer= 30;
    this.#inactivityService.reset();
  }
}
