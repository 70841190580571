import { ChangeDetectionStrategy, Component, computed, inject, input } from '@angular/core';
import { GlobalVarService } from '@core/services';

@Component({
  selector: 'app-spinner',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [],
  templateUrl: './spinner.component.html',
  styleUrl: './spinner.component.scss'
})
export class SpinnerComponent {
  readonly #globalVarService = inject(GlobalVarService);

  showSpinner = computed(() => this.#globalVarService.showSpinner());
  loadingText = input('');
}
