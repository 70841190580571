<p-dialog [closable]="false" [modal]="true" [visible]="show()"
  [style]="{ width: '90%', maxWidth: '500px', }">
  <ng-template pTemplate="headless">
    <section class="bg-principal relative h-full text-center p-6">
      <header class="mb-7">
        <div class="mb-4">
          <img class="img-icon" src="/assets/images/icons/tiempo.png" alt="Se te acaba el tiempo">
        </div>
        <h1 class="title font-bold mb-2">¿Necesitas más tiempo?</h1>
        <div class="decoration"></div>
      </header>
      <div>
        <p class="paragraph mb-6">
          Por seguridad, si no continúas con la operación, tu sessión se cerrará automáticamente en
          <strong>{{timer}}</strong> segundos.
        </p>
        <button type="button" class="block mx-auto w-9 sm:w-6 justify-content-center" pButton
          (click)="onClose()">Aceptar</button>
      </div>
    </section>
  </ng-template>
</p-dialog>
