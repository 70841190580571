import { createAction, props } from "@ngrx/store";

import { Phone } from "@equipos/interfaces";


export const savePhones = createAction(
  '[Phone]: Phones Successfully Saved',
  props<{ value: Phone[] }>()
);

export const saveInactiveRegion = createAction(
  '[Phone]: Region InactivaSuccessfully Saved',
  props<{ value: number }>()
);

export const saveCreditLimit = createAction(
  '[Phone]: Credit Limit Successfully Saved',
  props<{ value: number }>()
);

export const savePromotionCode = createAction(
  '[Phone]: Promotion Code Successfully Saved',
  props<{ code: string, value: number }>()
);

export const initPromotionCode = createAction(
  '[Phone]: Promotion Code Successfully Reset',
);

export const AddingPhonesWithPromitionCode = createAction(
  '[Phone]: Adding Phones With Discount',
  props<{ value: Phone[]}>()
);

export const saveReservationIDAndSerie = createAction(
  '[Phone]: Reservation ID And Serie Successfully Saved',
  props<{ reservationId: string, serie: string}>()
);

export const saveSeriePhone = createAction(
  '[Phone]: Serie Successfully Saved',
  props<{ value: string}>()
);

export const savePhone = createAction(
  '[Phone]: Phone Successfully Saved',
  props<{ value: Phone}>()
);

export const initPhone = createAction(
  '[Phone]: Phone Successfully Reset',
);
