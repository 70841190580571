export function calculate(
  precioTotal: number,
  pagoInicial: number,
  porcentajeSobreprecio: number,
  totalCuotas: number
) {
  const financiamiento = precioTotal - pagoInicial;
  const sobreprecio = financiamiento * porcentajeSobreprecio;
  const precioFinal = financiamiento + sobreprecio;
  const pagoPorCuotas = precioFinal / totalCuotas;

  return { financiamiento, sobreprecio, precioFinal, pagoPorCuotas }

}

export function calPorcentajeEnganche(precioTotal: number, engancheActualizado: number) {
  const porcentaje = (engancheActualizado/precioTotal);
  return porcentaje
}
