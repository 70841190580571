import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';

import { manejarError, superUrl } from '@core/helpers';
import { environment } from '@envs/environment';
import { PagosPlazos } from '@equipos/interfaces';
import { GenerarOrdenPagoInterface } from '../interfaces';
import moment from 'moment';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SicatelService {
  #http = inject(HttpClient);

  backButton = new Subject<boolean>();


  get url() {
    return superUrl;
  }

  get tokenAndPhone() {
    return {
      numeroCelular: sessionStorage.getItem('numeroCelular'),
      token: sessionStorage.getItem('tokenTelcel')
    }
  }


  sicatelGetOrdenPago() {
    const headers = new HttpHeaders({
      'x-api-key': environment.apiKey_sicatelGenerarOrdenPago
    });

    return this.#http.post<GenerarOrdenPagoInterface>(`${this.url}/sicatelGetOrdenPago`,
      this.tokenAndPhone,
      { headers }
    ).pipe(
      map((response) => {
        if (!response.process) throw response.message;

        const { responseObject } = response;
        responseObject.fechaCreacion = moment.unix(responseObject.fechaCreacion as number / 1000).format("DD/MM/YYYY");
        responseObject.fechaVigencia = new Date(responseObject.fechaVigencia).toLocaleDateString('es-Mx', { day: 'numeric', month: 'long', year: 'numeric' });
        sessionStorage.setItem('dataConekta', JSON.stringify(responseObject));

        return responseObject;
      }),
      catchError((error) => manejarError(error))
    )
  }

  sicatelGenerarOrdenPago() {
    const pagosPlazos: PagosPlazos = JSON.parse(sessionStorage.getItem('pagosPlazos')!);

    const body = {
      ...this.tokenAndPhone,
      payload: {
        cuenta: sessionStorage.getItem('externalId'),
        concepto: 1,
        montoEnganche: Number(pagosPlazos.enganche),
        canalVenta: Number(sessionStorage.getItem('idFlujo')),
        regionFuerzaVenta: Number(sessionStorage.getItem('region')),
        email: sessionStorage.getItem('emailIngresado')
      }
    }

    const headers = new HttpHeaders({
      'x-api-key': environment.apiKey_sicatelGenerarOrdenPago
    });

    return this.#http.post<GenerarOrdenPagoInterface>(`${this.url}/sicatelGenerarOrdenService`,
      body,
      { headers }
    ).pipe(
      map((response) => {
        if (!response.process) throw response.message;
        const { responseObject } = response;

        responseObject.fechaCreacion = moment.unix(responseObject.fechaCreacion as number / 1000).format("DD/MM/YYYY");
        responseObject.fechaVigencia = moment.unix(responseObject.fechaVigencia as number / 1000).format("DD/MM/YYYY");
        sessionStorage.setItem('dataSicatel', JSON.stringify(responseObject));

        return responseObject;
      }),
      catchError((error) => manejarError(error))
    );
  }

}
