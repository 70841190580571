import { ChangeDetectionStrategy, Component, computed, inject } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';

import { GlobalVarService } from '@core/services';

@Component({
  selector: 'app-error-modal',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    DialogModule,
    ButtonModule
  ],
  templateUrl: './error-modal.component.html',
  styleUrl: './error-modal.component.scss'
})
export class ErrorModalComponent {
  readonly #globalVarService = inject(GlobalVarService);

  show = computed(() => this.#globalVarService.showErrorModal());
  message = computed(() => this.#globalVarService.errorModalMessage());


  onAccept() {
    this.#globalVarService.onCloseErrorModal();
  }
}
