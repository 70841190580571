import { createReducer, on } from "@ngrx/store";

import { AddressAttributesInterface } from '../../interfaces';
import { INIT_ADDRESS_STATE } from "../../utilities";
import { generateSMTAppointment, removeSucursalAndDatosEnvio, saveAddress, saveDatosEnvio, saveDeliverySchedule, saveDeliveryType, saveFolioEnvio, saveFolioReserva, saveIdHorario, saveNombrePaqueteria, saveSucursal } from "./address.actions";


const addressInitialState = INIT_ADDRESS_STATE;


export const AddressAttributesReducer = createReducer(
  addressInitialState,
  on(
    saveDeliveryType,
    (state, { value }): AddressAttributesInterface => ({ ...state, tipoEntregaSeleccionada: value })
  ),

  on(
    saveDeliverySchedule,
    (state, { value }): AddressAttributesInterface => ({ ...state, deliverySchedule: value })
  ),

  on(
    saveFolioReserva,
    (state, { value }): AddressAttributesInterface => ({ ...state, folioReserva: value })
  ),

  on(
    saveIdHorario,
    (state, { value }): AddressAttributesInterface => ({ ...state, idHorario: value })
  ),

  on(
    saveFolioEnvio,
    (state, { value }): AddressAttributesInterface => ({ ...state, folioEnvio: value })
  ),

  on(
    saveDatosEnvio,
    (state, { value }): AddressAttributesInterface => ({ ...state, datosEnvio: value })
  ),

  on(
    saveSucursal,
    (state, { value }): AddressAttributesInterface => ({ ...state, sucursalSeleccionada: value })
  ),

  on(
    saveAddress,
    (state, { value }): AddressAttributesInterface => ({ ...state, direccion: value })
  ),

  on(
    saveNombrePaqueteria,
    (state, { value }): AddressAttributesInterface => ({ ...state, nombrePaqueteria: value })
  ),


  on(
    removeSucursalAndDatosEnvio,
    (state): AddressAttributesInterface => ({ ...state, sucursalSeleccionada: undefined, datosEnvio: undefined })
  ),

  on(
    generateSMTAppointment,
    (state): AddressAttributesInterface => ({ ...state })
  ),
);
