import { createAction, props } from "@ngrx/store";

import { BranchOffice, DeliverySchedule } from "@entrega-equipo/interfaces";
import { DatosEnvio } from "@entrega-equipo/interfaces";


export const saveDeliveryType = createAction(
  '[Address]: Delivery Type Successfully Saved',
  props<{value: 'domicilio' | 'tienda'}>()
);


export const saveDeliverySchedule = createAction(
  '[Address]: Delivery Schedule Successfully Saved',
  props<{value: DeliverySchedule}>()
);

export const saveFolioReserva = createAction(
  '[Address]: Folio Reserva Successfully Saved',
  props<{value: string}>()
);

export const saveIdHorario = createAction(
  '[Address]: ID Horario Successfully Saved',
  props<{value: string}>()
);

export const saveFolioEnvio = createAction(
  '[Address]: Folio Envio Successfully Saved',
  props<{value: string}>()
);

export const saveDatosEnvio = createAction(
  '[Address]: Datos Envio Successfully Saved',
  props<{value: DatosEnvio}>()
);

export const saveSucursal = createAction(
  '[Address]: Sucursal Successfully Saved',
  props<{value: BranchOffice}>()
);

export const saveNombrePaqueteria = createAction(
  '[Address]: Nombre Paqueteria Successfully Saved',
  props<{value: string}>()
);

export const saveAddress = createAction(
  '[Address]: Address Successfully Generated',
  props<{value: string}>()
);

export const removeSucursalAndDatosEnvio = createAction(
  '[Address]: Sucursal And Datos Envio Successfully Removed',
);

export const generatingSMTAppointment = createAction(
  '[Address]: Generating SMT Appointment',
);


export const generateSMTAppointment = createAction(
  '[Address]: SMT Appointment Successfully Generated'
);
