import { Injectable, signal } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PagoSharedService {
  #clickingBackButton = signal(false);
  #viewingCardPaymentNotice = signal(false);
  #viewingCardPayment = signal(false);

  get clickingBackButton() {
    return this.#clickingBackButton.asReadonly();
  }

  get viewingCardPaymentNotice() {
    return this.#viewingCardPaymentNotice.asReadonly();
  }

  get viewingCardPayment() {
    return this.#viewingCardPayment.asReadonly();
  }


  onClickingBackButton(value: boolean) {
    this.#clickingBackButton.set(value);
  }

  onViewingCardPaymentNotice(value: boolean) {
    this.#viewingCardPaymentNotice.set(value);
  }

  onViewCardPayment(value: boolean) {
    this.#viewingCardPayment.set(value);
  }
}
