import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { catchError, map } from 'rxjs/operators';

import { toSignal } from '@angular/core/rxjs-interop';
import { ServicesNames } from '@core/enums';
import { manejarError, superUrl } from '@core/helpers';
import { environment } from '@envs/environment';
import { Phone, PhoneDetails } from '@equipos/interfaces';
import { saveClientExternalID, selectClientAttributesFeatures, selectGlobalAttributesFeatures, selectIdReservacion, selectPhone, selectRegion, selectSeriePhone } from '@store/index';
import { EmailEdgeResponse, GetSolicitudResponse, PostSolicitudResponse, PutSolicitudResponse, SolicitaCuentaResponse } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class SolicitaCuentaService {
  readonly #http = inject(HttpClient);
  readonly #store = inject(Store);

  readonly #globalAttributes = toSignal(this.#store.select(selectGlobalAttributesFeatures));
  readonly #clientAttributes = this.#store.selectSignal(selectClientAttributesFeatures);
  readonly #phoneAttributes = this.#store.selectSignal(selectPhone);
  readonly #seriePhone = this.#store.selectSignal(selectSeriePhone);
  readonly #idReservacioPhone = this.#store.selectSignal(selectIdReservacion);
  readonly #region = toSignal(this.#store.select(selectRegion));


  get url() {
    return superUrl;
  }


  solicitarCuenta() {
    const headers = new HttpHeaders({
      'x-api-key': environment.apiKey_SolicitaCuenta
    });

    return this.#http.post<SolicitaCuentaResponse>(
      `${this.url}/${ServicesNames.SOLICITA_CUENTA}`,
      {},
      { headers }
    ).pipe(
      map((response) => {
        if (!response.process || response.identifier === -1) throw response.message;

        this.#store.dispatch(saveClientExternalID({ value: response.responseObject.data.external_id }));
        return response.responseObject;
      }),
      catchError((error) => manejarError(error))
    );
  }

  getSolicitud() {
    const headers = new HttpHeaders({
      'x-api-key': environment.apiKey_getSolicitud
    });

    const body = {
      payload: null
    }

    return this.#http.post<GetSolicitudResponse>(
      `${this.url}/${ServicesNames.GET_SOLICITUD}`,
      body,
      { headers }
    ).pipe(
      map((response) => {
        if (!response.process) throw response.message;
        if (response.identifier === -1) return Number(response.identifier);

        this.#store.dispatch(saveClientExternalID({ value: response.responseObject.external_id }));
        return response.responseObject.external_id;
      }),
      catchError((error) => manejarError(error))
    )
  }

  putSolicitud(payload: any) {
    const headers = new HttpHeaders({
      'x-api-key': environment.apiKey_putSolicitud
    });

    const precioSinIva = (Number(this.#phoneAttributes()!.precio_iva || 0) / 1.16).toFixed(2);

    const body = {
      payload: {
        icc: this.#clientAttributes().icc,
        promocion: this.#phoneAttributes()!.condicionespromocion ? true : false,
        imei: this.#seriePhone(), // Si el flujo es DAT, no es necesario enviarte esta propiedad
        score: this.#clientAttributes().bcc,
        riesgo: this.#clientAttributes()!.nivel_riesgo,
        almacen: this.#globalAttributes()!.oficinaId, // Esto está estatico
        numCuenta: this.#clientAttributes()!.externalID,
        region: this.#region(), // Region del vendedor o de la oferta
        // scoreTelco: null sessionStorage.getItem('scoreTelco'),
        scoreTelco: null,
        fzaVenta: this.#globalAttributes()!.fuerzaVenta,
        folioReserva: this.#idReservacioPhone(),
        agente: this.#globalAttributes()!.agente,
        idEmpleado: this.#globalAttributes()!.idEmpleado,
        nombreComercial: this.#phoneAttributes()!.nombre_comercial,
        marca: this.#phoneAttributes()!.marca,
        modeloComercial: this.#phoneAttributes()!.modelo_comercial,
        modelo: this.#phoneAttributes()!.modelo,
        precioSinIva: precioSinIva,
        ...payload
      }
    }

    return this.#http.post<PutSolicitudResponse>(
      `${this.url}/${ServicesNames.PUT_SOLICITUD}`,
      body,
      { headers }
    ).pipe(
      map((response) => {
        if (!response.process) throw response.message;
        this.#store.dispatch(saveClientExternalID({ value: response.responseObject.data.external_id }));
        return response.responseObject;
      }),
      catchError((error) => manejarError(error))
    )
  }

  postPrestamo() {
    const headers = new HttpHeaders({
      'x-api-key': environment.apiKey_postPrestamos
    });

    const body = {
      payload: {
        numCuenta: this.#clientAttributes()!.externalID
      }
    }

    return this.#http.post<PostSolicitudResponse>(
      `${this.url}/${ServicesNames.POST_PRESTAMO}`,
      body,
      { headers }
    ).pipe(
      map((response) => {
        if (!response.process) throw response.message;
        return response.responseObject;
      }),
      catchError((error) => manejarError(error))
    );
  }

  emailEdge() {
    const headers = new HttpHeaders({
      'x-api-key': environment.apiKey_emailEdge
    });

    const body = {
      payload: {
        numOrden: this.#clientAttributes()!.externalID,
        idTransaccion: this.#clientAttributes().idTransaccion
      }
    }

    return this.#http.post<EmailEdgeResponse>(
      `${this.url}/${ServicesNames.EMAIL_EDGE}`,
      body,
      { headers }
    ).pipe(
      map((response) => {
        if (!response.process) throw response.message;
        return response.responseObject;
      }),
      catchError((error) => manejarError(error))
    )
  }
}
