import { ActionReducer } from "@ngrx/store";
import { localStorageSync } from "ngrx-store-localstorage";

import { AppState } from "@store/interfaces";




export function localStorageSyncReducer(reducer: ActionReducer<AppState>): ActionReducer<AppState> {
  return localStorageSync({
    keys: ['global', 'client', 'phone', 'payment', 'address'], // Claves a sincronizar
    storage: sessionStorage,
    rehydrate: true // Restaura el estado al cargar la app
  })(reducer);
}
